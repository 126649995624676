import { Dispatch, SetStateAction, useEffect } from "react";
import { COLORS } from "../../../common/styles/colors";
import { ErrorDTO, ValidationError } from "../interestFormTypes";
import styled from "styled-components";
import { isNonBlankString } from "../../../common/utils/stringUtils";

type Props = {
  errors: ErrorDTO[];
  setValidationFailures?: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
};

const ErrorP = styled.p`
  color: ${COLORS.LIGHT_RED};
  text-align: justify;
`;

const Errors = ({ errors, setValidationFailures }: Props) => {
  useEffect(() => {
    const msgCount = errors.reduce(
      (acc, error) => acc + (isNonBlankString(error.msg) ? 1 : 0),
      0
    );
    if (errors.length > 0 && msgCount === 0)
      errors.push({ msg: "Sono presenti errori" });

    if (!setValidationFailures) return;

    const failedFields = Array.from(
      new Set(errors.flatMap((error) => error.failures || []))
    );
    if (failedFields.length === 0) return;

    const validationFailures = failedFields.reduce<ValidationError>(
      (acc, field) => {
        acc[field] = true;
        return acc;
      },
      {}
    );
    setValidationFailures(validationFailures);
  }, [errors, setValidationFailures]);

  return (
    <div id="errors">
      {errors.map(
        (error, i) =>
          error.msg && <ErrorP key={`error${i}`}>{error.msg}</ErrorP>
      )}
    </div>
  );
};

export default Errors;
