import styled from "styled-components";
import { MAIN_PADDING } from "../../common/constants/numeric";
import { selectHeaderHeight } from "../../common/slices/headerSlice";
import { selectBreadcrumbHeight } from "../../common/slices/breadcrumbsSlice";
import { GlobalState } from "../../common/types/GlobalState";
import { connect } from "react-redux";
import wipImage from "../../common/images/photos/qfvg-wip.webp";
import { COLORS } from "../../common/styles/colors";

type Props = {
  headerHeight: number;
  breadcrumbsHeight: number;
};

const CenterDiv = styled.div<{
  $headerHeight: number;
  $breadcrumbsHeight: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: calc(
    100vh -
      ${({ $headerHeight, $breadcrumbsHeight }) =>
        $headerHeight + 2 * MAIN_PADDING + $breadcrumbsHeight}px
  );
  color: ${COLORS.MID_ORANGE};
`;

const WorkInProgressPage = ({ headerHeight, breadcrumbsHeight }: Props) => (
  <CenterDiv
    $headerHeight={headerHeight}
    $breadcrumbsHeight={breadcrumbsHeight}
  >
    <h3>Stiamo ancora preparando questa pagina!</h3>
    <img
      alt=""
      src={wipImage}
      style={{ maxWidth: "75vw", maxHeight: "50vh" }}
    />
  </CenterDiv>
);

const mapStateToProps = (state: GlobalState) => {
  return {
    headerHeight: selectHeaderHeight(state),
    breadcrumbsHeight: selectBreadcrumbHeight(state),
  };
};

export default connect(mapStateToProps)(WorkInProgressPage);
