import { Variant } from "../enums/Variant";

export type HomePageCardType = {
  internalId: string;
  markdown?: string;
  variant?: Variant;
  bgImageUrl?: string;
  columns?: number;
  customStyle?: string;
  pageRoute?: string;
  dynamicPageId?: string;
  externalLink?: string;
  order?: number;
  active: boolean;
};

export const populateDefaults = (card: HomePageCardType): HomePageCardType => ({
  ...card,
  variant: card.variant || Variant.Primary,
  columns: card.columns || 1,
});

export const compare = (a: HomePageCardType, b: HomePageCardType) => {
  const compareActive = a.active === b.active ? 0 : a.active ? -1 : 1;
  if (compareActive !== 0) return compareActive;

  const compareOrder = (a.order ?? 999) - (b.order ?? 999);
  return compareOrder !== 0
    ? compareOrder
    : (a.markdown ?? "").localeCompare(b.markdown ?? "");
};
