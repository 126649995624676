export const isStringChanged = (
  s1: string | null | undefined,
  s2: string | null | undefined
): boolean => {
  const isEmpty = (str: string | null | undefined): boolean =>
    str === null || str === undefined || str === "";

  if (isEmpty(s1)) return !isEmpty(s2);
  if (isEmpty(s2)) return !isEmpty(s1);
  return s1 !== s2;
};

export const isNonBlankString = (s: string | null | undefined): boolean =>
  s ? s !== "" : false;
