import React from "react";
import ReactDOM from "react-dom/client";
import "./common/styles/common-styles.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { initializeApp } from "./common/slices/appSlice";

const rootElement = document.getElementById("root");
if (rootElement != null) {
  const root = ReactDOM.createRoot(rootElement);
  store.dispatch(initializeApp());
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
