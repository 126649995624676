import React, { CSSProperties, ReactNode } from "react";
import { styled } from "styled-components";
import { Variant, variantToColors } from "../enums/Variant";
import Link from "./links/Link";

type Props = {
  style?: CSSProperties;
  children?: ReactNode;
  variant?: Variant;
  bgImageUrl?: string;
  href?: string;
  target?: string;
  className?: string;
  grow?: boolean;
};

type StyleProps = {
  $variant?: Variant;
  $bgImage?: string;
  $isLink?: boolean;
};

const StyledDiv = styled.div<StyleProps>`
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  background-color: ${({ $variant }) =>
    variantToColors[$variant || Variant.Primary]["main"]};
  color: ${({ $variant }) =>
    variantToColors[$variant || Variant.Primary]["text"]};
  font-family: sans-serif;
  border-radius: 10px;
  margin: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 1.5em;
  ${({ $bgImage }) =>
    $bgImage &&
    `
  background-image: url(${$bgImage});
  background-size: cover;
  background-position: center center;
  background-blend-mode: soft-light;
  `}

  ${({ $isLink, $variant }) =>
    $isLink &&
    `&:hover {
    background-color: ${variantToColors[$variant || Variant.Primary]["active"]};
  }`}

  ${({ $isLink }) =>
    $isLink &&
    `&:active {
    transform: scale(0.95);
  }`}

  * {
    text-align: center;
  }
`;

const Tile = ({
  style,
  children,
  variant,
  bgImageUrl,
  href,
  target,
  className,
  grow,
}: Props) => {
  const component = (
    <StyledDiv
      $variant={variant}
      $bgImage={bgImageUrl}
      $isLink={!!href}
      style={style}
      className={className}
    >
      <span>{children}</span>
    </StyledDiv>
  );

  return href ? (
    <Link
      to={href}
      target={target ?? "_self"}
      style={{ display: "flex", flexGrow: grow ? 1 : 0 }}
    >
      {component}
    </Link>
  ) : (
    component
  );
};

export default Tile;
