import ReactModal from "react-modal";
import Button from "../buttons/Button";
import { COLORS, hexToRgbWithAlpha } from "../../styles/colors";
import { Variant } from "../../enums/Variant";
import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  closeEdit: () => void;
  children?: ReactNode;
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  flex-wrap: wrap;
  margin: 40px auto 0 auto;
`;

const StackableButton = styled(Button)`
  flex: 1 0 auto;
`;

const Dialog = ({ isOpen, closeModal, closeEdit, children }: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          zIndex: 2,
          background: COLORS.BLACK,
          border: `solid 1px ${COLORS.MID_ORANGE}`,
          borderRadius: "20px",
          height: "fit-content",
          width: "fit-content",
          margin: "auto",
          padding: "5vh 5vw",
        },
        overlay: {
          zIndex: 2,
          background: hexToRgbWithAlpha(COLORS.BLACK, 0.75),
        },
      }}
    >
      {children}
      <ButtonContainer>
        <StackableButton variant={Variant.Secondary} onClick={closeModal}>
          Annulla
        </StackableButton>
        <StackableButton onClick={closeEdit}>OK</StackableButton>
      </ButtonContainer>
    </ReactModal>
  );
};

export default Dialog;
