import { MutableRefObject, useContext, useRef, useState } from "react";
import { InterestFormContext } from "../InterestForm";
import {
  ErrorDTO,
  InterestFormState,
  ValidationError,
} from "../../interestFormTypes";
import Input from "../../../../common/components/inputs/Input";
import { isNonBlankString } from "../../../../common/utils/stringUtils";
import Errors from "../Errors";
import { CheckboxInputWrapper } from "../../interestFormComponents";
import BackAndNext from "../BackAndNext";

const Step4 = () => {
  const { answers, answerStep } = useContext(InterestFormContext);
  const [errors, setErrors] = useState([] as ErrorDTO[]);
  const [validationFailures, setValidationFailures] = useState(
    {} as ValidationError
  );

  const onNext = () => {
    setErrors([]);
    try {
      validate();
    } catch (e: any) {
      return;
    }
    answerStep(
      {
        moreInfo: {
          quadball: quadballRef.current?.checked,
          team: teamRef.current?.checked,
          commitment: commitmentRef.current?.checked,
          cost: costRef.current?.checked,
          other: otherRef.current?.value,
        },
      } as InterestFormState,
      9
    );
  };

  const validate = () => {
    const errs = [];

    const quadball = quadballRef.current?.checked;
    const team = teamRef.current?.checked;
    const commitment = commitmentRef.current?.checked;
    const cost = costRef.current?.checked;
    const other = otherRef.current?.value;

    if (!quadball && !team && !commitment && !cost && !isNonBlankString(other))
      errs.push({
        failures: ["quadball", "team", "commitment", "cost", "other"],
        msg: "Indica almeno un argomento che vorresti approfondire",
      });

    if (errs.length > 0) {
      setErrors(errs);
      throw new Error("Validation errors present");
    }
  };

  const quadballRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const teamRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const commitmentRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const costRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const otherRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );

  return (
    <div>
      <center>&ldquo;Vorrei avere più dettagli su...&rdquo; *</center>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <CheckboxInputWrapper>
          <CheckboxInputWrapper>
            <Input
              ref={quadballRef}
              id="moreInfoQuadball"
              type="checkbox"
              label="Quadball"
              value={answers.moreInfo?.quadball}
              validationFailed={validationFailures.quadball}
            />
            <Input
              ref={teamRef}
              id="moreInfoTeam"
              type="checkbox"
              label="Squadra"
              value={answers.moreInfo?.team}
              validationFailed={validationFailures.team}
            />
          </CheckboxInputWrapper>
          <CheckboxInputWrapper>
            <Input
              ref={commitmentRef}
              id="moreInfoCommitment"
              type="checkbox"
              label="Impegno"
              value={answers.moreInfo?.commitment}
              validationFailed={validationFailures.commitment}
            />
            <Input
              ref={costRef}
              id="moreInfoCost"
              type="checkbox"
              label="Costi"
              value={answers.moreInfo?.cost}
              validationFailed={validationFailures.cost}
            />
          </CheckboxInputWrapper>
        </CheckboxInputWrapper>
        <Input
          ref={otherRef}
          id="moreInfoRequest"
          type="textarea"
          label="Altro"
          value={answers.moreInfo?.other}
          validationFailed={validationFailures.other}
        />
      </div>
      <BackAndNext onNext={onNext} />
      <Errors errors={errors} setValidationFailures={setValidationFailures} />
    </div>
  );
};

export default Step4;
