import { connect, useDispatch } from "react-redux";
import { DynamicPageType } from "../../../common/types/DynamicPageType";
import { GlobalState } from "../../../common/types/GlobalState";
import {
  getEDynamicPageRequest,
  selectPageForEditor,
} from "../../dynamicpage/slices/dynamicPageSlice";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DynamicPageDisplay from "../../dynamicpage/components/DynamicPageDisplay";
import { APP_TITLE } from "../../../common/constants/strings";

type Props = {
  page?: DynamicPageType;
};

const EditorDynamicPagePreview = ({ page }: Props) => {
  const { dynamicPageId } = useParams();
  const dispatch = useDispatch();

  document.title =
    APP_TITLE + " | Preview" + (page?.title ? " | " + page.title : "");

  useEffect(() => {
    dispatch(getEDynamicPageRequest(dynamicPageId));
  }, [dispatch, dynamicPageId]);

  return page ? <DynamicPageDisplay page={page} /> : <></>;
};

const mapStateToProps = (state: GlobalState) => {
  return {
    page: selectPageForEditor(state),
  };
};

export default connect(mapStateToProps)(EditorDynamicPagePreview);
