import { CSSProperties, ChangeEvent, useRef } from "react";
import Button from "../buttons/Button";

type Props = {
  handleFile: (file: File) => any;
  style?: CSSProperties;
};

const FileInput = ({ handleFile, style }: Props) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    const hiddenFileInputEl =
      hiddenFileInput.current as unknown as HTMLInputElement;
    hiddenFileInputEl?.click();
  };

  const handleChange = (e: ChangeEvent) => {
    const files = (e.target as HTMLInputElement)?.files;
    if (files && files.length) {
      handleFile(files[0]);
    }
  };

  const handleInputClick = (e: React.MouseEvent<HTMLElement>) => {
    (e.target as HTMLInputElement).value = "";
  };

  return (
    <>
      <Button onClick={handleClick} style={style}>
        Carica immagine
      </Button>
      <input
        ref={hiddenFileInput}
        type="file"
        accept="image/gif,image/jpeg,image/png,image/webp"
        style={{ display: "none" }}
        onChange={handleChange}
        onClick={handleInputClick}
      />
    </>
  );
};

export default FileInput;
