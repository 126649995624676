import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GlobalState } from "../types/GlobalState";

type State = {
  height: number;
};

const initialState = {
  height: 0,
};

const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    updateHeight: (state: State, { payload }: PayloadAction<number>) => {
      state.height = payload;
    },
  },
});

export const { updateHeight } = breadcrumbsSlice.actions;

export const selectBreadcrumbHeight = (state: GlobalState) =>
  state.breadcrumbs?.height;

export default breadcrumbsSlice.reducer;
