import { useContext } from "react";
import { InterestFormContext } from "./InterestForm";
import NextButton from "./buttons/NextButton";

type Props = {
  onNext: () => void;
};

const BackAndNext = ({ onNext }: Props) => {
  const { backButton } = useContext(InterestFormContext);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {backButton}
      <NextButton onClick={onNext} />
    </div>
  );
};

export default BackAndNext;
