import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../constants/appRoutes";
import { COLORS } from "../styles/colors";
import { APP_TITLE } from "../constants/strings";
import { useDispatch } from "react-redux";
import { updateHeight } from "../slices/breadcrumbsSlice";
import { AccentLink } from "./links/styledLinks";

const BreadcrumbsWrapper = styled.div`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  gap: 5px;
  color: ${COLORS.WHITE};
  font-size: 14px;
  font-weight: bold;

  span:not(:last-child)::after {
    content: " > ";
  }
`;

const buildBreadcrumbs = (
  referencePath: string
): { path?: string; display?: string }[] => {
  const breadcrumbs: { path?: string; display?: string }[] = [];

  const traverse = (obj: any) => {
    for (const key in obj) {
      if (key !== "path" && key !== "display") {
        const newPath = (obj[key] as any).path;
        const newDisplay = (obj[key] as any).display;
        const regexPath = newPath?.replace(/:[a-zA-Z][a-zA-Z0-9]*/g, "[^/]+");
        const regex = new RegExp(`^${regexPath}$`);
        if (regex.test(referencePath)) {
          if (newDisplay) {
            breadcrumbs.push({ display: newDisplay.replace(/ /g, "\u00A0") });
            document.title = APP_TITLE + " | " + newDisplay;
          }
          return true;
        }
        if (traverse(obj[key] as any)) {
          if (newDisplay)
            breadcrumbs.push({
              path: newPath,
              display: newDisplay.replace(/ /g, "\u00A0"),
            });
          return true;
        }
      }
    }
    return false;
  };

  traverse(routes);

  return breadcrumbs.some((item) => item.path) ? breadcrumbs.reverse() : [];
};

const Breadcrumbs = () => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState(
    undefined as unknown as { path?: string; display?: string }[]
  );

  useEffect(() => {
    const path = location.pathname.replace(process.env.PUBLIC_URL, "");
    setBreadcrumbs(buildBreadcrumbs(path));
  }, [location.pathname]);

  const dispatch = useDispatch();
  const breadcrumbsRef: any = useRef(null);

  useEffect(() => {
    const breadcrumbsElement = breadcrumbsRef?.current;

    const observer = new ResizeObserver((entries) => {
      dispatch(updateHeight(entries[0].contentRect.height));
    });
    observer.observe(breadcrumbsElement);

    return () => breadcrumbsElement && observer.unobserve(breadcrumbsElement);
  }, [dispatch]);

  return (
    <BreadcrumbsWrapper ref={breadcrumbsRef}>
      {breadcrumbs &&
        breadcrumbs.map(
          (breadcrumb) => (
            <span>
              {breadcrumb.path ? (
                <AccentLink href={breadcrumb.path}>
                  {breadcrumb.display}
                </AccentLink>
              ) : (
                breadcrumb.display
              )}
            </span>
          ),
          ">"
        )}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
