import StepNo from "./StepNo";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
import Step10 from "./Step10";
import { useContext } from "react";
import { InterestFormContext } from "../InterestForm";
import StepNoWithComments from "./StepNoWithComments";

const Step = () => {
  const { step } = useContext(InterestFormContext);

  switch (step) {
    case -2:
      return <StepNoWithComments />;
    case -1:
      return <StepNo />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
    case 7:
      return <Step7 />;
    case 8:
      return <Step8 />;
    case 9:
      return <Step9 />;
    case 10:
      return <Step10 />;
    default:
      return null;
  }
};

export default Step;
