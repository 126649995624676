import { MutableRefObject, useContext, useRef, useState } from "react";
import { InterestFormContext } from "../InterestForm";
import {
  ErrorDTO,
  InterestFormState,
  ValidationError,
} from "../../interestFormTypes";
import Input from "../../../../common/components/inputs/Input";
import { isValidPhoneNumber } from "libphonenumber-js";
import { isNonBlankString } from "../../../../common/utils/stringUtils";
import Errors from "../Errors";
import { InterestFormCheckbox } from "../../interestFormComponents";
import BackAndNext from "../BackAndNext";

const Step3 = () => {
  const { answers, answerStep } = useContext(InterestFormContext);
  const [errors, setErrors] = useState([] as ErrorDTO[]);
  const [validationFailures, setValidationFailures] = useState(
    {} as ValidationError
  );

  const nameRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const birthYearRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const noBirthYearRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const locationRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const noLocationRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const emailRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const fbRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const whatsappRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const telegramRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );

  const onNext = () => {
    setErrors([]);
    try {
      validate();
    } catch (e: any) {
      return;
    }
    answerStep(
      {
        contacts: {
          name: nameRef.current?.value,
          birthYear: birthYearRef.current?.value,
          location: locationRef.current?.value,
          email: emailRef.current?.value,
          fb: fbRef.current?.value,
          whatsapp: whatsappRef.current?.value,
          telegram: telegramRef.current?.value,
        },
      } as InterestFormState,
      answers.needMoreInfo ? 4 : 5
    );
  };

  const validate = () => {
    const errs = [];

    const name = nameRef.current?.value;
    const birthYear = birthYearRef.current?.value;
    const noBirthYearFlag = noBirthYearRef.current?.checked;
    const location = locationRef.current?.value;
    const noLocationFlag = noLocationRef.current?.checked;
    const email = emailRef.current?.value;
    const fb = fbRef.current?.value;
    const whatsapp = whatsappRef.current?.value;
    const telegram = telegramRef.current?.value;

    if (!isNonBlankString(name))
      errs.push({
        failures: ["name"],
        msg: "Come ti possiamo chiamare?",
      });

    if (!noBirthYearFlag && !isNonBlankString(birthYear)) {
      errs.push({
        failures: ["birthYear"],
      });
    } else if (isNonBlankString(birthYear)) {
      const yearNumber = +birthYear;
      const currentYear = new Date().getFullYear();
      if (yearNumber > currentYear - 13 && yearNumber < currentYear)
        errs.push({
          failures: ["birthYear"],
          msg: "Scusa, al momento cerchiamo solo giocatori dai 14 anni in su",
        });
      if (yearNumber < currentYear - 100 || yearNumber >= currentYear)
        errs.push({
          failures: ["birthYear"],
          msg: "Iniziamo a sospettare che tu ci stia mentendo sulla tua età",
        });
    }

    if (!noLocationFlag && !isNonBlankString(location))
      errs.push({
        failures: ["location"],
      });

    if (
      !isNonBlankString(email) &&
      !isNonBlankString(fb) &&
      !isNonBlankString(whatsapp) &&
      !isNonBlankString(telegram)
    )
      errs.push({
        failures: ["email", "fb", "whatsapp", "telegram"],
        msg: "Inserisci almeno una modalità di contatto",
      });

    if (isNonBlankString(email) && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      errs.push({
        failures: ["email"],
        msg: "Inserisci un indirizzo valido come contatto email",
      });

    if (isNonBlankString(whatsapp) && !isValidPhoneNumber(whatsapp, "IT"))
      errs.push({
        failures: ["whatsapp"],
        msg: "Inserisci un numero di telefono valido come contatto WhatsApp",
      });

    if (errs.length > 0) {
      setErrors(errs);
      throw new Error("Validation errors present");
    }
  };

  return (
    <>
      <div>
        <div>
          <Input
            ref={nameRef}
            id="name"
            type="text"
            label="Nome *"
            value={answers.contacts?.name}
            validationFailed={validationFailures.name}
          />
          <Input
            ref={birthYearRef}
            id="name"
            type="number"
            label="Anno di nascita *"
            value={answers.contacts?.birthYear}
            validationFailed={validationFailures.birthYear}
          />
          <InterestFormCheckbox bigger ref={noBirthYearRef} /> Preferisco non
          rispondere
          <Input
            ref={locationRef}
            id="location"
            type="text"
            label="Domicilio *"
            value={answers.contacts?.location}
            validationFailed={validationFailures.location}
          />
          <InterestFormCheckbox bigger ref={noLocationRef} /> Preferisco non
          rispondere
          <Input
            ref={emailRef}
            id="email"
            type="text"
            label="Email"
            value={answers.contacts?.email}
            validationFailed={validationFailures.email}
          />
          <Input
            ref={fbRef}
            id="fb"
            type="text"
            label="Facebook"
            value={answers.contacts?.fb}
            validationFailed={validationFailures.fb}
          />
          <Input
            ref={whatsappRef}
            id="whatsapp"
            type="text"
            label="WhatsApp"
            value={answers.contacts?.whatsapp}
            validationFailed={validationFailures.whatsapp}
          />
          <Input
            ref={telegramRef}
            id="telegram"
            type="text"
            label="Telegram"
            value={answers.contacts?.telegram}
            validationFailed={validationFailures.telegram}
          />
        </div>
      </div>
      <BackAndNext onNext={onNext} />
      <Errors errors={errors} setValidationFailures={setValidationFailures} />
    </>
  );
};

export default Step3;
