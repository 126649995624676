import { createSlice } from "@reduxjs/toolkit";
import { GlobalState } from "../types/GlobalState";

type State = {
  uploading: boolean;
};

const initialState = {
  uploading: false,
};

const imgSlice = createSlice({
  name: "img",
  initialState,
  reducers: {
    startUploadingImage: (state: State) => {
      state.uploading = true;
    },
    stopUploadingImage: (state: State) => {
      state.uploading = false;
    },
  },
});

export const { startUploadingImage, stopUploadingImage } = imgSlice.actions;

export const selectImageUploading = (state: GlobalState) =>
  state.img?.uploading;

export default imgSlice.reducer;
