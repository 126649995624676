import styled from "styled-components";
import { ReactComponent as ThreeDots } from "../images/svg/3-dots-fade.svg";
import { CSSProperties } from "react";
import { COLORS, hexToRgbWithAlpha } from "../styles/colors";
import { LOADER_MARGIN } from "../constants/numeric";

type Props = {
  style?: CSSProperties;
};

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 5%,
    ${hexToRgbWithAlpha(COLORS.DARK_ORANGE, 0.25)} 5%,
    ${hexToRgbWithAlpha(COLORS.DARK_ORANGE, 0.25)} 10%
  );
  border-radius: 10px;
  margin: ${LOADER_MARGIN}px;
`;

const ThreeOrangeDots = styled(ThreeDots)`
  fill: ${COLORS.DARK_ORANGE};
  width: 20%;
  height: auto;
`;

const Loader = ({ style }: Props) => (
  <Overlay style={style}>
    <ThreeOrangeDots />
  </Overlay>
);

export default Loader;
