import { apiRoutes } from "../constants/appRoutes";
import { startUploadingImage, stopUploadingImage } from "../slices/imgSlice";
import store from "../../redux/store";

export const imageCdnUpload = async (img: File, path?: string) => {
  store.dispatch(startUploadingImage());
  const formData = new FormData();
  formData.append("image", img);
  const response = await fetch(
    apiRoutes.IMAGE_UPLOAD.replace(":path", path ?? ""),
    {
      method: "POST",
      body: formData,
    }
  );
  const json = (await response.json()) as { url: string };
  store.dispatch(stopUploadingImage());

  return json.url;
};
