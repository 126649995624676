import styled, { css } from "styled-components";
import { COLORS } from "../../styles/colors";
import Button from "../buttons/Button";
import StyleEditor from "react-style-editor";
import { Checkbox } from "pretty-checkbox-react";
import { VALIDATION_FAILED_CLASS } from "../../constants/strings";

export const inputCss = css`
  background: ${COLORS.BLACK};
  color: ${COLORS.WHITE} !important;
  border: solid 1px ${COLORS.MID_ORANGE} !important;
  border-radius: 7px;
  width: 100%;
  box-sizing: border-box;

  &.${VALIDATION_FAILED_CLASS} {
    border-color: ${COLORS.ERROR_RED} !important;
  }
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const Label = styled.label`
  display: flex;
  position: relative;
  top: 17px;
  height: fit-content;
  width: fit-content;
  background-color: ${COLORS.BLACK};
  color: ${COLORS.MID_ORANGE};
  clip-path: inset(0 2px);
  padding: 0 5px;
  z-index: 1;
`;

export const TextInput = styled.input`
  ${inputCss}
`;

export const TextAreaInput = styled.textarea`
  ${inputCss}
  resize: vertical;
`;

export const CssInput = styled(StyleEditor)`
  ${inputCss}
`;

export const CheckboxInput = styled(Checkbox)`
  display: flex;

  width: 82px;
  min-height: calc(5em + 2px);

  input {
    display: none;
  }

  .state label:before {
    top: 0 !important;
    border-color: ${COLORS.MID_ORANGE};
  }

  &.${VALIDATION_FAILED_CLASS} .state label:before {
    border-color: ${COLORS.ERROR_RED};
  }

  &.pretty {
    margin-right: 0;
  }

  &.pretty.p-default input:checked ~ .state label:after {
    top: 0 !important;
    background-color: ${COLORS.DARK_ORANGE} !important;
  }

  &.pretty.p-bigger label:after,
  &.pretty.p-bigger label:before,
  &.pretty.p-bigger .svg,
  &.pretty.p-bigger .img,
  &.pretty.p-bigger .icon {
    font-size: 5em !important;
  }

  &.pretty.p-bigger label {
    text-indent: 3.4em;
  }
`;

export const OptionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputFieldButton = styled(Button)`
  flex: 0 0 auto;
  padding: 10px;
  margin-left: 0;
`;

export const Warning = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex: 1 1 auto;
  font-size: 0.8em;
  padding: 5px 0;
`;
