import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import FAIcon from "../../../../common/components/FAIcon";
import { BigButton } from "../../interestFormComponents";

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

const NextButton = ({ onClick, disabled = false }: Props) => (
  <BigButton onClick={onClick} disabled={disabled} style={{ flex: 1 }}>
    <FAIcon icon={faChevronRight} />
  </BigButton>
);

export default NextButton;
