import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
} from "react";
import { imageCdnUpload } from "../../utils/imgUtils";
import FileInput from "./FileInput";
import { TextInput, OptionButtons } from "./inputStyledComponents";
import { VALIDATION_FAILED_CLASS } from "../../constants/strings";

type Props = {
  id: string;
  onUpdate?: () => void;
  handleBlur: FocusEventHandler<HTMLInputElement>;
  currentStringValue: string;
  setCurrentStringValue: React.Dispatch<React.SetStateAction<string>>;
  path?: string;
  validationFailed?: boolean;
};

const ImageInput = forwardRef(
  (
    {
      id,
      onUpdate,
      handleBlur,
      currentStringValue,
      setCurrentStringValue,
      path,
      validationFailed = false,
    }: Props,
    ref: ForwardedRef<any>
  ) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      setCurrentStringValue(event.target.value);
      onUpdate && onUpdate();
    };

    return (
      <>
        <TextInput
          ref={ref}
          id={id}
          type="text"
          defaultValue={currentStringValue}
          onChange={handleChange}
          onBlur={handleBlur}
          className={validationFailed ? VALIDATION_FAILED_CLASS : ""}
        />
        <OptionButtons>
          {/* TODO: Add error handling + delete on discard? (maybe purge routine looking in specific fields) */}
          <FileInput
            handleFile={async (f) => {
              const url = await imageCdnUpload(f, path);
              setCurrentStringValue(url);

              if (typeof ref !== "function") {
                const current = ref?.current;
                if (current) current.value = url;
              }
              onUpdate && onUpdate();
            }}
            style={{ flex: "0 0 auto", padding: 10, marginLeft: 0 }}
          />
        </OptionButtons>
      </>
    );
  }
);

export default ImageInput;
