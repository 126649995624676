import MarkdownIt from "markdown-it";
import parse from "html-react-parser";
import styled from "styled-components";

const markdown = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
});

type Props = {
  customStyle?: string;
  children?: string;
};

type StyleProps = {
  $customStyle?: string;
};

const StyledDiv = styled.div<StyleProps>`
  ${({ $customStyle }) => $customStyle}
`;

const Markdown = ({ customStyle, children }: Props) => {
  return (
    <StyledDiv $customStyle={customStyle}>
      {children && parse(markdown.render(children))}
    </StyledDiv>
  );
};

export default Markdown;
