import { connect, useDispatch } from "react-redux";
import { DynamicPageType } from "../../../common/types/DynamicPageType";
import { GlobalState } from "../../../common/types/GlobalState";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DynamicPageEditor from "./components/DynamicPageEditor";
import { routes } from "../../../common/constants/appRoutes";
import {
  getEDynamicPageRequest,
  selectPageForEditor,
} from "../../dynamicpage/slices/dynamicPageSlice";

type Props = {
  page: DynamicPageType;
};

const EditorDynamicPageEdit = ({ page }: Props) => {
  const { dynamicPageId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dynamicPageId && dispatch(getEDynamicPageRequest(dynamicPageId));
  }, [dispatch, dynamicPageId]);

  return (
    page && (
      <DynamicPageEditor
        page={page}
        closeEditMode={() => {
          window.location.href = routes.HOME.EDIT.DYNAMIC_PAGE_LIST.path;
        }}
      />
    )
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    page: selectPageForEditor(state),
  };
};

export default connect(mapStateToProps)(EditorDynamicPageEdit);
