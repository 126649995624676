import styled from "styled-components";
import { COLORS } from "../../common/styles/colors";

export const EditorLink = styled.a`
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  align-items: center;
  color: ${COLORS.MID_ORANGE};
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
`;

export const EditorFooterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
`;
