import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GlobalState } from "../common/types/GlobalState";

type State = {
  location: string;
};

const routerSlice = createSlice({
  name: "router",
  initialState: {} as State,
  reducers: {
    updateLocation: (state: State, { payload }: PayloadAction<any>) => {
      state.location = payload;
    },
  },
});

export const { updateLocation } = routerSlice.actions;

export const selectLocation = (state: GlobalState) => {
  return state.router?.location;
};

export default routerSlice.reducer;
