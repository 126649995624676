import axios, { AxiosResponse } from "axios";
import { HomePageCardDTO } from "../../../common/types/dtos/HomePageCardDTO";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getECardFailure,
  getECardRequest,
  getECardSuccess,
  homePageCardsFailure,
  homePageCardsRequest,
  homePageCardsSuccess,
  homePageECardsFailure,
  homePageECardsRequest,
  homePageECardsSuccess,
  saveCardFailure,
  saveCardRequest,
  saveCardSuccess,
  sortCardsFailure,
  sortCardsRequest,
  sortCardsSuccess,
} from "../slices/homePageSlice";
import { apiRoutes } from "../../../common/constants/appRoutes";
import { replace } from "lodash";

const getHomePageCards = async () => {
  return await axios.get(apiRoutes.CARD.GET_ACTIVE);
};

const homePageCardsWorker = function* () {
  try {
    const response: AxiosResponse<HomePageCardDTO[], any> = yield call(
      getHomePageCards
    );
    yield put(homePageCardsSuccess(response.data));
  } catch (e) {
    yield put(homePageCardsFailure());
  }
};

const homePageCardsWatcher = function* () {
  yield takeLatest(homePageCardsRequest, homePageCardsWorker);
};

const getHomePageCardsForEdit = async () => {
  return await axios.get(apiRoutes.CARD.EDIT.LIST);
};

const homePageECardsWorker = function* () {
  try {
    const response: AxiosResponse<HomePageCardDTO[], any> = yield call(
      getHomePageCardsForEdit
    );
    yield put(homePageECardsSuccess(response.data));
  } catch (e) {
    yield put(homePageECardsFailure());
  }
};

const homePageECardsWatcher = function* () {
  yield takeLatest(homePageECardsRequest, homePageECardsWorker);
};

const saveCard = async (payload: { card: HomePageCardDTO; id: string }) => {
  return await axios.post(
    replace(apiRoutes.CARD.SAVE, ":cardId", payload.id ?? ""),
    payload.card
  );
};

const saveCardWorker = function* (action: {
  payload: { card: HomePageCardDTO; id: string };
}) {
  try {
    const response: AxiosResponse<HomePageCardDTO, any> = yield call(
      saveCard,
      action.payload
    );
    yield put(saveCardSuccess(response.data));
  } catch (e) {
    yield put(saveCardFailure());
  }
};

const saveCardWatcher = function* () {
  yield takeLatest(saveCardRequest, saveCardWorker);
};

const getCardForEditor = async (cardId: string) => {
  return await axios.get(replace(apiRoutes.CARD.EDIT.GET, ":cardId", cardId));
};

const getECardWorker = function* (action: { payload: string }) {
  try {
    const response: AxiosResponse<HomePageCardDTO, any> = yield call(
      getCardForEditor,
      action.payload
    );
    yield put(getECardSuccess(response.data));
  } catch (e) {
    yield put(getECardFailure());
  }
};

const getECardWatcher = function* () {
  yield takeLatest(getECardRequest, getECardWorker);
};

const sortCards = async (payload: string[]) => {
  return await axios.post(apiRoutes.CARD.SORT, payload);
};

const sortCardsWorker = function* (action: { payload: string[] }) {
  try {
    const response: AxiosResponse<HomePageCardDTO[], any> = yield call(
      sortCards,
      action.payload
    );
    yield put(sortCardsSuccess(response.data));
  } catch (e) {
    yield put(sortCardsFailure());
  }
};

const sortCardsWatcher = function* () {
  yield takeLatest(sortCardsRequest, sortCardsWorker);
};

const homePageSaga = function* () {
  yield all([
    homePageCardsWatcher(),
    homePageECardsWatcher(),
    saveCardWatcher(),
    getECardWatcher(),
    sortCardsWatcher(),
  ]);
};

export default homePageSaga;
