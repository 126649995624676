import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { DraggableItemTypes } from "../../constants/dragNDrop";
import { ReactNode, useRef } from "react";
import React from "react";

type Props = {
  id: any;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  children: ReactNode;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const DraggableItem = ({ id, index, moveItem, children }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: DraggableItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Ignore undefined values
      if (dragIndex === undefined || hoverIndex === undefined) return;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DraggableItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    options: {
      dropEffect: "move",
    },
  }));

  const draggableStyle = {
    opacity: isDragging ? 0.5 : 1,
    cursor: isDragging ? "grabbing" : "grab",
  }; // TODO: fix opacity and cursor
  const childrenWithProps = React.Children.map(children, (child: any) =>
    React.cloneElement(child, {
      style: { ...(child?.props?.style || {}), ...draggableStyle },
    })
  );

  drag(drop(ref));

  return (
    <div ref={ref} style={{ display: "flex", justifyContent: "center" }}>
      {childrenWithProps}
    </div>
  );
};

export default DraggableItem;
