import { MutableRefObject, useContext, useRef } from "react";
import { InterestFormContext } from "../InterestForm";
import { InterestFormState } from "../../interestFormTypes";
import { CheckboxInputWrapper } from "../../interestFormComponents";
import Input from "../../../../common/components/inputs/Input";
import BackAndNext from "../BackAndNext";

const Step8 = () => {
  const { answers, answerStep } = useContext(InterestFormContext);

  const goRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const pnRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );
  const otherRef: MutableRefObject<HTMLInputElement> = useRef(
    null as unknown as HTMLInputElement
  );

  const onNext = () => {
    answerStep(
      {
        location: {
          GO: goRef.current?.checked,
          PN: pnRef.current?.checked,
          other: otherRef.current?.value,
        },
      } as InterestFormState,
      9
    );
  };

  return (
    <div>
      <center>Ci sono altre location che ti possono risultare comode?</center>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <CheckboxInputWrapper>
          <Input
            ref={goRef}
            id="locationGo"
            type="checkbox"
            label="Gorizia"
            value={answers.location?.GO}
          />
          <Input
            ref={pnRef}
            id="locationPn"
            type="checkbox"
            label="Pordenone"
            value={answers.location?.PN}
          />
        </CheckboxInputWrapper>
        <Input
          ref={otherRef}
          id="locationOther"
          type="textarea"
          label="Altre"
          value={answers.location?.other}
        />
      </div>
      <BackAndNext onNext={onNext} />
    </div>
  );
};

export default Step8;
