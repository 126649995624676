import axios, { AxiosResponse } from "axios";
import { replace } from "lodash";
import { apiRoutes } from "../../../common/constants/appRoutes";
import {
  getDynamicPageFailure,
  getDynamicPageRequest,
  getDynamicPageSuccess,
  saveDynamicPageFailure,
  saveDynamicPageRequest,
  saveDynamicPageSuccess,
} from "../slices/dynamicPageSlice";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { DynamicPageDTO } from "../../../common/types/dtos/DynamicPageDTO";
import {
  getEDynamicPageFailure,
  getEDynamicPageRequest,
  getEDynamicPageSuccess,
  listEDynamicPagesFailure,
  listEDynamicPagesRequest,
  listEDynamicPagesSuccess,
} from "../slices/dynamicPageSlice";

const getPage = async (dynamicPageId: string) => {
  return await axios.get(
    replace(apiRoutes.DYNAMIC_PAGE.GET, ":dynamicPageId", dynamicPageId)
  );
};

const getDynamicPageWorker = function* (action: { payload: string }) {
  try {
    const response: AxiosResponse<DynamicPageDTO, any> = yield call(
      getPage,
      action.payload
    );
    yield put(getDynamicPageSuccess(response.data));
  } catch (e) {
    yield put(getDynamicPageFailure());
  }
};

const getDynamicPageWatcher = function* () {
  yield takeLatest(getDynamicPageRequest, getDynamicPageWorker);
};

const savePage = async (payload: { page: DynamicPageDTO; id: string }) => {
  return await axios.post(
    replace(apiRoutes.DYNAMIC_PAGE.SAVE, ":dynamicPageId", payload.id ?? ""),
    payload.page
  );
};

const saveDynamicPageWorker = function* (action: {
  payload: { page: DynamicPageDTO; id: string };
}) {
  try {
    const response: AxiosResponse<DynamicPageDTO, any> = yield call(
      savePage,
      action.payload
    );
    yield put(saveDynamicPageSuccess(response.data));
  } catch (e) {
    yield put(saveDynamicPageFailure());
  }
};

const saveDynamicPageWatcher = function* () {
  yield takeLatest(saveDynamicPageRequest, saveDynamicPageWorker);
};

const listEDynamicPages = async () => {
  return await axios.get(apiRoutes.DYNAMIC_PAGE.EDIT.LIST);
};

const listEDynamicPagesWorker = function* () {
  try {
    const response: AxiosResponse<DynamicPageDTO[], any> = yield call(
      listEDynamicPages
    );
    yield put(listEDynamicPagesSuccess(response.data));
  } catch (e) {
    yield put(listEDynamicPagesFailure());
  }
};

const listEDynamicPageWatcher = function* () {
  yield takeLatest(listEDynamicPagesRequest, listEDynamicPagesWorker);
};

const getPageForEditor = async (dynamicPageId: string) => {
  return await axios.get(
    replace(apiRoutes.DYNAMIC_PAGE.EDIT.GET, ":dynamicPageId", dynamicPageId)
  );
};

const getEDynamicPageWorker = function* (action: { payload: string }) {
  try {
    const response: AxiosResponse<DynamicPageDTO, any> = yield call(
      getPageForEditor,
      action.payload
    );
    yield put(getEDynamicPageSuccess(response.data));
  } catch (e) {
    yield put(getEDynamicPageFailure());
  }
};

const getEDynamicPageWatcher = function* () {
  yield takeLatest(getEDynamicPageRequest, getEDynamicPageWorker);
};

const dynamicPageSaga = function* () {
  yield all([
    getDynamicPageWatcher(),
    saveDynamicPageWatcher(),
    listEDynamicPageWatcher(),
    getEDynamicPageWatcher(),
  ]);
};

export default dynamicPageSaga;
