import { MutableRefObject, RefObject, useContext, useRef } from "react";
import { InterestFormContext } from "../InterestForm";
import { InterestFormState } from "../../interestFormTypes";
import {
  InterestFormCheckbox,
  OptionsContainerColumn,
  OptionsContainerRow,
} from "../../interestFormComponents";
import React from "react";
import BackAndNext from "../BackAndNext";

type DayOfWeek = "monday" | "tuesday" | "wednesday" | "thursday" | "friday";

const Step7 = () => {
  const { answers, answerStep } = useContext(InterestFormContext);

  const checkboxRefs: MutableRefObject<RefObject<HTMLInputElement>[]> = useRef(
    Array.from({ length: 5 }, () => React.createRef())
  );

  const onNext = () => {
    const checkboxStates = checkboxRefs.current.map(
      (ref) => ref.current?.checked || false
    );

    answerStep(
      {
        availabilityWeek: {
          monday: checkboxStates[0],
          tuesday: checkboxStates[1],
          wednesday: checkboxStates[2],
          thursday: checkboxStates[3],
          friday: checkboxStates[4],
        },
      } as InterestFormState,
      8
    );
  };

  return (
    <div>
      <center>
        Non hai indicato nessun orario. Sei disponibile in settimana in orario
        serale?
      </center>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <OptionsContainerRow>
          <OptionsContainerColumn style={{ border: 0 }}>
            <span>Lunedì</span>
            <span>Martedì</span>
            <span>Mercoledì</span>
            <span>Giovedì</span>
            <span>Venerdì</span>
          </OptionsContainerColumn>
          <OptionsContainerColumn>
            {["monday", "tuesday", "wednesday", "thursday", "friday"].map(
              (day, dayIndex) => {
                return (
                  <InterestFormCheckbox
                    bigger
                    key={day}
                    ref={checkboxRefs.current[dayIndex]}
                    defaultChecked={
                      answers?.availabilityWeek?.[day as DayOfWeek]
                    }
                  />
                );
              }
            )}
          </OptionsContainerColumn>
        </OptionsContainerRow>
      </div>
      <BackAndNext onNext={onNext} />
    </div>
  );
};

export default Step7;
