import { connect, useDispatch } from "react-redux";
import { GlobalState } from "../../../common/types/GlobalState";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { routes } from "../../../common/constants/appRoutes";
import { HomePageCardType } from "../../../common/types/HomePageCardType";
import {
  getECardRequest,
  selectHomePageCard,
} from "../../home/slices/homePageSlice";
import CardEditor from "./components/CardEditor";

type Props = {
  card: HomePageCardType;
};

const EditorCardEdit = ({ card }: Props) => {
  const { cardId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    cardId && dispatch(getECardRequest(cardId));
  }, [dispatch, cardId]);

  return (
    card && (
      <CardEditor
        card={card}
        closeEditMode={() => {
          window.location.href = routes.HOME.EDIT.CARD_LIST.path;
        }}
      />
    )
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    card: selectHomePageCard(state),
  };
};

export default connect(mapStateToProps)(EditorCardEdit);
