import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { loginRequest, loginSuccess, loginFailure } from "./loginSlice";
import { LoginRequest } from "./types/loginTypes";
import { apiRoutes } from "../../common/constants/appRoutes";

// Mock login API call
const apiLogin = async (credentials: LoginRequest) =>
  await axios.post(apiRoutes.LOGIN, credentials);

// Worker Saga: Fired on loginRequest action
const login = function* (action: any): any {
  try {
    yield call(apiLogin, action.payload);
    yield put(loginSuccess());
    window.location.href = "/";
  } catch (error: any) {
    yield put(loginFailure(error.response));
  }
};

// Watcher Saga: Watches for loginRequest action and calls login saga
const watchLogin = function* () {
  yield takeLatest(loginRequest, login);
};

export default watchLogin;
