import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GlobalState } from "../../../common/types/GlobalState";

type State = {
  id?: number;
  error?: boolean;
};

const interestFormSlice = createSlice({
  name: "interestForm",
  initialState: {},
  reducers: {
    saveInterestFormRequest: (state, action) => {},
    saveInterestFormSuccess: (
      state: State,
      { payload }: PayloadAction<number>
    ) => {
      state.id = payload;
      state.error = false;
    },
    saveInterestFormFailure: (state: State, payload) => {
      state.error = true;
    },
  },
});

export const {
  saveInterestFormRequest,
  saveInterestFormSuccess,
  saveInterestFormFailure,
} = interestFormSlice.actions;

export const selectInterestFormAnswerId = (state: GlobalState) =>
  state.interestForm?.id;
export const selectInterestFormError = (state: GlobalState) =>
  state.interestForm?.error;

export default interestFormSlice.reducer;
