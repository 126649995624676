import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  saveInterestFormFailure,
  saveInterestFormRequest,
  saveInterestFormSuccess,
  selectInterestFormAnswerId,
} from "../slice/interestFormSlice";
import { apiRoutes } from "../../../common/constants/appRoutes";
import axios, { AxiosResponse } from "axios";
import { InterestFormState } from "../interestFormTypes";
import { replace } from "lodash";

const saveInterestForm = async (payload: {
  answers: InterestFormState;
  id: number;
}) => {
  return await axios.post(
    replace(apiRoutes.INTEREST_FORM, ":leadId", payload.id?.toString() ?? ""),
    payload.answers
  );
};

const saveInterestFormWorker = function* (action: {
  payload: InterestFormState;
}) {
  try {
    const id: number = yield select(selectInterestFormAnswerId);
    const response: AxiosResponse<number, any> = yield call(saveInterestForm, {
      answers: action.payload,
      id,
    });
    yield put(saveInterestFormSuccess(response.data));
  } catch (e: any) {
    yield put(saveInterestFormFailure(e.response));
  }
};

export const saveInterestFormWatcher = function* () {
  yield takeLatest(saveInterestFormRequest, saveInterestFormWorker);
};

const interestFormSaga = function* () {
  yield saveInterestFormWatcher();
};

export default interestFormSaga;
