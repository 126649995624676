import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../../common/styles/colors";

const StyledLink = styled(Link)`
  color: ${COLORS.MID_ORANGE};
  font-weight: bold;

  &:hover {
    color: ${COLORS.WHITE};
  }
`;

const WhatsappLink = () => (
  <p>
    Scrivici nel{" "}
    <StyledLink
      to="https://chat.whatsapp.com/CNVAV0s0V041UUQdYf1kPG"
      target="_blank"
    >
      Gruppo WhatsApp
    </StyledLink>
    !
  </p>
);

export default WhatsappLink;
