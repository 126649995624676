import styled from "styled-components";

const FullWidthDiv = styled.div`
  width: 100vw;
`;

type Props = {
  height: number;
  visible: boolean;
};

const Divider = ({ height, visible }: Props) => {
  return (
    <FullWidthDiv
      style={{ height: `${height}px`, display: visible ? "block" : "none" }}
    />
  );
};

export default Divider;
