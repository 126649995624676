export const routes = {
  HOME: {
    path: "/",
    display: "🏠 Home",
    DYNAMIC_PAGE: {
      path: "/p/:dynamicPageId",
    },
    EDIT: {
      path: "/edit",
      display: "📝 Editor",
      DYNAMIC_PAGE_LIST: {
        path: "/edit/page",
        display: "Gestione pagine dinamiche",
        DYNAMIC_PAGE_NEW: {
          path: "/edit/page/new",
          display: "Nuova pagina dinamica",
        },
        DYNAMIC_PAGE_EDIT: {
          path: "/edit/page/:dynamicPageId",
          display: "Modifica pagina dinamica",
        },
      },
      CARD_LIST: {
        path: "/edit/card",
        display: "Gestione Card Homepage",
        CARD_NEW: {
          path: "/edit/card/new",
          display: "Nuova Card Homepage",
        },
        CARD_EDIT: {
          path: "/edit/card/:cardId",
          display: "Modifica Card Homepage",
        },
      },
    },
  },
  INTEREST_FORM: {
    path: "/interest",
    display: "📋 Modulo manifestazione d'interesse",
    INTEREST_FORM_PRIVACY: {
      path: "/privacy/interest",
      display: "Informativa sulla privacy",
    },
  },
  LOGIN: {
    path: "/login",
    display: "Login",
  },
  DYNAMIC_PAGE_PREVIEW: {
    path: "/edit/page/:dynamicPageId/preview",
  },
  WORK_IN_PROGRESS: {
    path: "/wip",
    display: "Work in Progress",
  },
} as const;

export const apiRoutes = {
  LOGIN: "/api/login",
  LOGOUT: "/api/logout",
  USER_CONTEXT: "/api/user/context",
  CARD: {
    GET_ACTIVE: "/api/cards",
    SAVE: "/api/card/save/:cardId",
    SORT: "/api/card/sort",
    EDIT: {
      LIST: "/api/card/e",
      GET: "/api/card/e/:cardId",
    },
  },
  DYNAMIC_PAGE: {
    GET: "/api/page/:dynamicPageId",
    SAVE: "/api/page/save/:dynamicPageId",
    EDIT: {
      LIST: "/api/page/e",
      GET: "/api/page/e/:dynamicPageId",
    },
  },
  IMAGE_UPLOAD: "/api/img/upload/:path",
  INTEREST_FORM: "/api/interest/:leadId",
} as const;
