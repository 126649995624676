import React, { CSSProperties, ReactNode } from "react";
import { HomePageCardType } from "../../../common/types/HomePageCardType";
import { routes } from "../../../common/constants/appRoutes";
import { replace } from "lodash";
import Markdown from "../../../common/components/Markdown";
import Tile from "../../../common/components/Tile";
import styled from "styled-components";

type Props = {
  style?: CSSProperties;
  cardData: HomePageCardType;
  children?: ReactNode;
};

const getLink = (
  pageRoute?: string,
  dynamicPageId?: string,
  externalLink?: string
): string | undefined => {
  if (!pageRoute && !dynamicPageId && !externalLink) return undefined;
  return (
    pageRoute ??
    (dynamicPageId &&
      replace(
        routes.HOME.DYNAMIC_PAGE.path,
        ":dynamicPageId",
        dynamicPageId
      )) ??
    externalLink
  );
};

const StyledTile = styled(Tile)<{ $customStyle: string }>`
  ${({ $customStyle }) => $customStyle}
`;

const HomePageCard = ({
  style,
  children,
  cardData: {
    variant,
    bgImageUrl,
    markdown,
    customStyle,
    pageRoute,
    dynamicPageId,
    externalLink,
  },
}: Props) => {
  const link = getLink(pageRoute, dynamicPageId, externalLink);
  const target = link === externalLink ? "_blank" : "_self";

  return (
    <StyledTile
      $customStyle={customStyle ?? ""}
      variant={variant}
      bgImageUrl={bgImageUrl}
      href={link}
      target={target}
      style={style}
      grow
    >
      <span>{<Markdown>{markdown}</Markdown>}</span>
      <span>{children}</span>
    </StyledTile>
  );
};

export default HomePageCard;
