import { useContext, useEffect, useRef } from "react";
import { InterestFormContext } from "../components/InterestForm";
import { useSelector } from "react-redux";
import { selectInterestFormError } from "../slice/interestFormSlice";
import { InterestFormState } from "../interestFormTypes";

const useFinalSave = () => {
  const hasRun = useRef(false);
  const { answerStep } = useContext(InterestFormContext);
  const error = useSelector(selectInterestFormError);

  const answerStepRef = useRef(answerStep);

  useEffect(() => {
    /**
     * If error is true, it means that the previous save or multiple saves failed.
     * We attempt to save everything one last time in a new record, to avoid losing data.
     */
    if (error && !hasRun.current) {
      answerStepRef.current({ email: true } as InterestFormState);
      hasRun.current = true;
    }
  }, [error]);
};

export default useFinalSave;
