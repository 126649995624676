import React from "react";
import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import AnimatedSuccessButton from "./AnimatedSuccessButton";
import AnimatedFailureButton from "./AnimatedFailureButton";

type OptionalAny = any | undefined;
type Props = {
  state: any;
  error: boolean;
  initialState?: any;
  preStates?: OptionalAny[];
  postStates?: OptionalAny[];
  style?: CSSProperties;
  delay?: number;
  children: ReactNode;
};

const ButtonWithNotification = ({
  children,
  state,
  error,
  initialState,
  preStates,
  postStates,
  style,
  delay,
}: Props) => {
  const stateRef = useRef<any | undefined>(initialState);
  const [showNotification, setShowNotification] = useState(false);
  useEffect(() => {
    if (
      !preStates ||
      (stateRef.current && preStates.includes(stateRef.current))
    ) {
      if (!postStates || postStates.includes(state)) {
        setShowNotification(true);
      }
    }
    stateRef.current = state;
  }, [state, preStates, postStates]);

  const childrenWithProps = React.Children.map(children, (child: any) =>
    React.cloneElement(child, { style: style })
  );

  return (
    <>
      {showNotification ? (
        error ? (
          <AnimatedFailureButton
            style={style}
            onAnimationEnd={() =>
              setTimeout(() => setShowNotification(false), delay ?? 0)
            }
          />
        ) : (
          <AnimatedSuccessButton
            style={style}
            onAnimationEnd={() =>
              setTimeout(() => setShowNotification(false), delay ?? 0)
            }
          />
        )
      ) : (
        childrenWithProps
      )}
    </>
  );
};

export default ButtonWithNotification;
