import { ReactNode } from "react";
import styled from "styled-components";
import { COLORS } from "../styles/colors";

type Props = {
  onClick: () => void;
  children: ReactNode;
};

const StyledButton = styled.button`
  border: solid ${COLORS.MID_ORANGE} 2px;
  padding: 0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: none;
`;

const HeaderButton = ({ onClick, children }: Props) => (
  <StyledButton onClick={onClick}>{children}</StyledButton>
);

export default HeaderButton;
