import styled, { RuleSet } from "styled-components";
import { COLORS } from "../styles/colors";
import { Link } from "react-router-dom";

type Props = {
  media: "video" | "photo";
  plural?: boolean;
  title: string;
  url: string;
  background?: RuleSet<object>;
};

type StyleProps = {
  $background?: RuleSet<object>;
};

const Label = styled.div<StyleProps>`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  color: ${COLORS.WHITE};
  font-size: min(3vw, 12px);
  text-align: right;
  ${({ $background }) => $background ?? ""}

  & a {
    color: ${COLORS.WHITE};
    font-weight: bold;
  }
`;

const CreditsLabel = ({ media, plural, title, url, background }: Props) => (
  <Label $background={background}>
    {(media + (plural ? "s" : "")).toUpperCase()} COURTESY OF{" "}
    <Link to={url} target="_blank">
      {title.toUpperCase().replace(/ /g, "\u00a0")}
    </Link>
  </Label>
);
export default CreditsLabel;
