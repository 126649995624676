import { useCallback, useEffect, useState } from "react";
import { HomePageCardType } from "../../../../common/types/HomePageCardType";
import HomePageCard from "../../../home/components/HomePageCard";
import { GlobalState } from "../../../../common/types/GlobalState";
import {
  selectHomePageCardSaveError,
  selectHomePageCardSaving,
  selectHomePageCards,
  sortCardsRequest,
} from "../../../home/slices/homePageSlice";
import { connect, useDispatch } from "react-redux";
import DraggableItem from "../../../../common/components/dragndrop/DraggableItem";
import { isEqual } from "lodash";
import EditorButtonsMultiModal from "../../../../common/components/buttons/EditorButtonsMultiModal";
import MultiModalContext from "../../../../common/contexts/MultiModalContext";

type Props = {
  closeSortMode: () => void;
  cards: HomePageCardType[];
  saving: boolean;
  saveError: boolean;
};

const CardSorter = ({ cards, closeSortMode, saving, saveError }: Props) => {
  const dispatch = useDispatch();

  const [sortedCards, setSortedCards] = useState(cards);

  useEffect(() => {
    setSortedCards(cards);
  }, [cards]);

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setSortedCards((prevCards: HomePageCardType[]) => {
      const cardsCopy = [...prevCards]; // Create a copy of the previous cards array
      const draggedCard = cardsCopy[dragIndex]; // Store the dragged card

      // Remove the dragged card from its original position
      cardsCopy.splice(dragIndex, 1);
      // Insert the dragged card at the new position
      cardsCopy.splice(hoverIndex, 0, draggedCard);

      return cardsCopy;
    });
  }, []);

  const areThereChanges = () => !isEqual(cards, sortedCards);

  const save = () => {
    dispatch(
      sortCardsRequest(
        sortedCards.map((card: HomePageCardType) => card.internalId)
      )
    );
  };

  const editorButtons = (
    <EditorButtonsMultiModal
      saving={saving}
      saveError={saveError}
      save={save}
      areThereChanges={areThereChanges}
      closeEditMode={closeSortMode}
    />
  );

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <MultiModalContext.Provider value={{ isModalOpen, setModalOpen }}>
        {editorButtons}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
          }}
        >
          {sortedCards.map((c, i) => (
            <DraggableItem id={c?.internalId} index={i} moveItem={moveItem}>
              <HomePageCard
                cardData={{
                  ...c,
                  pageRoute: undefined,
                  dynamicPageId: undefined,
                  externalLink: undefined,
                  customStyle: "> span { font-size: 75%; }",
                }}
                style={{
                  maxWidth: "max(min(600px, 100%), 50%)",
                  height: "50px",
                  userSelect: "none",
                }}
              />
            </DraggableItem>
          ))}
        </div>
        {editorButtons}
      </MultiModalContext.Provider>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    cards: selectHomePageCards(state).filter((c: HomePageCardType) => c.active),
    saving: selectHomePageCardSaving(state),
    saveError: selectHomePageCardSaveError(state),
  };
};

export default connect(mapStateToProps)(CardSorter);
