import axios from "axios";
import { apiRoutes } from "../constants/appRoutes";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { initializeApp, userContextSuccess } from "../slices/appSlice";

const getUserContext = async () => await axios.get(apiRoutes.USER_CONTEXT);

const userContextWorker = function* (): any {
  try {
    const response = yield call(getUserContext);
    yield put(userContextSuccess(response));
  } catch (error: any) {}
};

const initializeAppWorker = function* () {
  yield all([call(userContextWorker)]);
};

const userContextWatcher = function* () {
  yield takeLatest(initializeApp, initializeAppWorker);
};

const appSaga = function* () {
  yield userContextWatcher();
};

export default appSaga;
