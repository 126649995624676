import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  HomePageCardType,
  compare,
  populateDefaults,
} from "../../../common/types/HomePageCardType";
import { GlobalState } from "../../../common/types/GlobalState";
import {
  HomePageCardDTO,
  convertDTO,
} from "../../../common/types/dtos/HomePageCardDTO";
import { updateHistory } from "../../../common/utils/historyUtils";

type State = {
  homePageCard?: HomePageCardType;
  homePageCards?: HomePageCardType[];
  loading: boolean;
  loadError: boolean;
  saving: boolean;
  saveError: boolean;
};

const initialState = {
  homePageCards: [] as HomePageCardType[],
  loading: true,
  loadError: true,
  saving: false,
  saveError: false,
};

const homePageSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    homePageCardsRequest: () => {},
    homePageCardsSuccess: (
      state: State,
      { payload }: PayloadAction<HomePageCardDTO[]>
    ) => {
      state.homePageCards = payload
        .map(convertDTO)
        .map(populateDefaults)
        .sort(compare);
      state.loading = false;
    },
    homePageCardsFailure: (state: State) => {
      state.loading = false;
    },
    homePageECardsRequest: () => {},
    homePageECardsSuccess: (
      state: State,
      { payload }: PayloadAction<HomePageCardDTO[]>
    ) => {
      state.homePageCards = payload.map(convertDTO).sort(compare);
      state.loading = false;
    },
    homePageECardsFailure: (state: State) => {
      state.loading = false;
    },
    saveCardRequest: (state, action) => {
      state.saving = true;
      state.saveError = false;
    },
    saveCardSuccess: (
      state: State,
      { payload }: PayloadAction<HomePageCardDTO>
    ) => {
      const newPage = convertDTO(payload);
      if (
        !state.homePageCard?.internalId ||
        state.homePageCard.internalId !== newPage.internalId
      ) {
        updateHistory(newPage.internalId);
      }
      state.homePageCard = newPage;
      state.saving = false;
    },
    saveCardFailure: (state) => {
      state.saveError = true;
      state.saving = false;
    },
    getECardRequest: (state, action) => {},
    getECardSuccess: (
      state: State,
      { payload }: PayloadAction<HomePageCardDTO>
    ) => {
      state.homePageCard = convertDTO(payload);
      state.loading = false;
    },
    getECardFailure: (state) => {
      state.loadError = true;
      state.loading = false;
    },
    sortCardsRequest: (state, action) => {
      state.saving = true;
      state.saveError = false;
    },
    sortCardsSuccess: (
      state: State,
      { payload }: PayloadAction<HomePageCardDTO[]>
    ) => {
      state.homePageCards = payload.map(convertDTO).sort(compare);
      state.saving = false;
    },
    sortCardsFailure: (state) => {
      state.saveError = true;
      state.saving = false;
    },
  },
});

export const {
  homePageCardsRequest,
  homePageCardsSuccess,
  homePageCardsFailure,
  homePageECardsRequest,
  homePageECardsSuccess,
  homePageECardsFailure,
  saveCardRequest,
  saveCardSuccess,
  saveCardFailure,
  getECardRequest,
  getECardSuccess,
  getECardFailure,
  sortCardsRequest,
  sortCardsSuccess,
  sortCardsFailure,
} = homePageSlice.actions;

export const selectHomePageCard = (state: GlobalState) =>
  state.home?.homePageCard;
export const selectHomePageCards = (state: GlobalState) =>
  state.home?.homePageCards;
export const selectHomePageLoading = (state: GlobalState) =>
  state.home?.loading;
export const selectHomePageCardSaving = (state: GlobalState) =>
  state.home?.saving;
export const selectHomePageCardSaveError = (state: GlobalState) =>
  state.home?.saveError;

export default homePageSlice.reducer;
