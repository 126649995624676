import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GlobalState } from "../../common/types/GlobalState";
import { AxiosResponse } from "axios";

type State = {
  error?: string;
};

type LoginResponse = {
  message: string;
};

const loginSlice = createSlice({
  name: "login",
  initialState: {},
  reducers: {
    loginRequest: () => {
      // You can handle loading state if needed
    },
    loginSuccess: (state: State) => {
      state.error = undefined;
    },
    loginFailure: (
      state: State,
      { payload: { data } }: PayloadAction<AxiosResponse<LoginResponse, any>>
    ) => {
      state.error = data.message;
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure } = loginSlice.actions;

export const selectError = (state: GlobalState) => state.login?.error;

export default loginSlice.reducer;

// Asynchronous action (using Redux Thunk or Redux Saga) can be handled outside the slice.
// You may still need a saga file if you go with Redux Saga.
