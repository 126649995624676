import { MutableRefObject, useCallback } from "react";
import { isStringChanged } from "./stringUtils";

export const useIsValueChangedCallback = (
  ref: MutableRefObject<HTMLInputElement>,
  value: any
) => {
  return useCallback(() => {
    return !!ref.current && isStringChanged(ref.current?.value, value);
  }, [ref, value]);
};
