import { entries, reduce } from "lodash";

export const breakpoints = {
  sm: { minWidth: 576 },
  md: { minWidth: 788 },
  lg: { minWidth: 992 },
  xl: { minWidth: 1200 },
} as const;

export const minWidthMedia = reduce(
  entries(breakpoints),
  (acc, [key, { minWidth }]) => ({
    ...acc,
    // Generate media query strings with specific min width ranges
    // Design is mobile first, so the defaults rules are thought to work with mobile
    [key]: `@media (min-width: ${minWidth}px)`,
  }),
  {}
) as Record<keyof typeof breakpoints, string>;
