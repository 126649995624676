import { connect, useDispatch } from "react-redux";
import { DynamicPageType } from "../../../common/types/DynamicPageType";
import { GlobalState } from "../../../common/types/GlobalState";
import {
  listEDynamicPagesRequest,
  selectEDynamicPages,
} from "../../dynamicpage/slices/dynamicPageSlice";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { COLORS } from "../../../common/styles/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faFileCirclePlus,
  faFilePen,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { routes } from "../../../common/constants/appRoutes";
import { replace } from "lodash";
import { EditorFooterButtons, EditorLink } from "../editorComponents";
import { editorTableStyle } from "../editorStyle";

type Props = {
  pages?: DynamicPageType[];
};

const EditorDynamicPageList = ({ pages }: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listEDynamicPagesRequest());
  }, [dispatch]);

  const columns = [
    {
      name: "Titolo",
      cell: (row: DynamicPageType) => (
        <EditorLink
          href={`${routes.HOME.EDIT.DYNAMIC_PAGE_LIST.DYNAMIC_PAGE_EDIT.path.replace(
            ":dynamicPageId",
            row.internalId
          )}`}
        >
          <FontAwesomeIcon icon={faFilePen} /> {row.title}
        </EditorLink>
      ),
      sortable: true,
    },
    {
      name: "Attiva",
      cell: (row: DynamicPageType) =>
        row.active ? (
          <FontAwesomeIcon icon={faEye} color={COLORS.MID_GREEN} size="2x" />
        ) : (
          <FontAwesomeIcon icon={faEyeSlash} color={COLORS.MID_RED} size="2x" />
        ),
      sortable: true,
      grow: 0,
    },
    {
      name: "Anteprima",
      cell: (row: DynamicPageType) => (
        <EditorLink
          href={replace(
            routes.DYNAMIC_PAGE_PREVIEW.path,
            ":dynamicPageId",
            row.internalId
          )}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faUpRightFromSquare}
            color={COLORS.MID_ORANGE}
            size="2x"
          />
        </EditorLink>
      ),
      sortable: true,
      grow: 0,
    },
  ];

  return (
    <>
      {pages ? (
        <>
          {/* TODO: fix sorting */}
          <DataTable
            keyField={"internalId"}
            columns={columns}
            data={pages}
            customStyles={{
              headRow: editorTableStyle,
              rows: editorTableStyle,
              responsiveWrapper: { style: { boxSizing: "border-box" } },
            }}
            pagination
          />
          <EditorFooterButtons>
            <EditorLink
              href={routes.HOME.EDIT.DYNAMIC_PAGE_LIST.DYNAMIC_PAGE_NEW.path}
            >
              <FontAwesomeIcon icon={faFileCirclePlus} /> Nuova pagina
            </EditorLink>
          </EditorFooterButtons>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    pages: selectEDynamicPages(state),
  };
};

export default connect(mapStateToProps)(EditorDynamicPageList);
