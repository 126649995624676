import { faSmile } from "@fortawesome/free-solid-svg-icons";
import { Variant } from "../../enums/Variant";
import FAIcon from "../FAIcon";
import Button from "./Button";
import { CSSProperties } from "react";

type Props = {
  style?: CSSProperties;
  onAnimationEnd?: () => void;
  className?: string;
};

const AnimatedSuccessButton = ({ style, onAnimationEnd, className }: Props) => (
  <Button variant={Variant.Success} style={style} className={className}>
    <FAIcon
      icon={faSmile}
      style={{
        animation: "fa-bounce 2s linear 0s 1 normal none running",
      }}
      onAnimationEnd={onAnimationEnd}
    />
  </Button>
);
export default AnimatedSuccessButton;
