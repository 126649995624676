import React, { useRef, useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../common/images/svg/qfvg-logo.svg";
import { updateHeight } from "../../common/slices/headerSlice";
import { connect, useDispatch } from "react-redux";
import { routes, apiRoutes } from "../constants/appRoutes";
import { GlobalState } from "../types/GlobalState";
import { selectLocation } from "../../router/routerSlice";
import { faPencil, faSignOut } from "@fortawesome/free-solid-svg-icons";
import FAIcon from "./FAIcon";
import { COLORS } from "../styles/colors";
import { selectCanEdit, selectIsUserInitialized } from "../slices/appSlice";
import styled from "styled-components";
import HeaderButton from "./HeaderButton";

const HEADER_PADDING = 20;
const LOGO_HEIGHT = 75;
const HEADER_FONT_SIZE = 1;
const THRESHOLD = 150;

type Props = {
  location?: string;
  canEdit?: boolean;
  isUserInitialized: boolean;
};

type StyleProps = {
  $baseSize: number;
  $scaleDelta: number;
};

const HeaderH1 = styled.h1<StyleProps>`
  font-size: ${({ $baseSize, $scaleDelta }) =>
    `${$baseSize * (1 - $scaleDelta * 0.25)}em`};

  @media (max-width: 355px) {
    &.xs-hide {
      display: none !important;
    }
  }
`;

const HeaderLinks = styled.div`
  display: flex;
  flex: 0 0 auto;
  gap: 5px;
`;

const StyledLogo = styled(Logo)`
  & path {
    fill: ${COLORS.MID_ORANGE} !important;
  }
`;

const Header = ({ location, canEdit, isUserInitialized }: Props) => {
  const dispatch = useDispatch();
  const headerRef: any = useRef(null);
  const [headerScaleDelta, setHeaderScaleDelta] = useState(0);

  useEffect(() => {
    const headerElement = headerRef?.current;

    const observer = new ResizeObserver((entries) => {
      dispatch(updateHeight(entries[0].contentRect.height));
    });
    observer.observe(headerElement);

    return () => headerElement && observer.unobserve(headerElement);
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setHeaderScaleDelta(Math.min(1, scrollY / THRESHOLD));
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  const logout = () => (window.location.href = apiRoutes.LOGOUT);

  const logo = (
    <StyledLogo
      style={{
        height: `${LOGO_HEIGHT * (1 - headerScaleDelta * 0.5)}px`,
        width: "auto",
      }}
    />
  );

  const headerButtonStyle = {
    width: "20px",
    height: "20px",
    animation: "fa-shake 2s linear 0s 1 normal none running",
    color: COLORS.MID_ORANGE,
  };

  return (
    <header ref={headerRef}>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
          padding: `${
            HEADER_PADDING * (1 - headerScaleDelta * 0.75)
          }px ${HEADER_PADDING}px`,
        }}
      >
        <div style={{ flex: "0 0 auto" }}>
          {location === routes.HOME.path ? (
            logo
          ) : (
            <a href={routes.HOME.path}>{logo}</a>
          )}
        </div>
        <HeaderH1
          $baseSize={HEADER_FONT_SIZE}
          $scaleDelta={headerScaleDelta}
          style={{
            position: "absolute",
            width: `calc(100vw - ${HEADER_PADDING * 2}px)`,
            textAlign: "center",
            zIndex: -1,
          }}
          className="xs-hide"
        >
          QUADBALL
          <br />
          FVG
        </HeaderH1>
        <HeaderLinks>
          {canEdit && (
            <HeaderButton
              onClick={() => (window.location.href = routes.HOME.EDIT.path)}
            >
              <HeaderH1
                $baseSize={HEADER_FONT_SIZE}
                $scaleDelta={headerScaleDelta}
              >
                <FAIcon icon={faPencil} style={headerButtonStyle} playOnHover />
              </HeaderH1>
            </HeaderButton>
          )}
          {isUserInitialized && (
            <HeaderButton onClick={logout}>
              <HeaderH1
                $baseSize={HEADER_FONT_SIZE}
                $scaleDelta={headerScaleDelta}
              >
                <FAIcon
                  icon={faSignOut}
                  style={headerButtonStyle}
                  playOnHover
                />
              </HeaderH1>
            </HeaderButton>
          )}
        </HeaderLinks>
      </div>
    </header>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    location: selectLocation(state),
    isUserInitialized: selectIsUserInitialized(state),
    canEdit: selectCanEdit(state),
  };
};

export default connect(mapStateToProps)(Header);
