import { connect } from "react-redux";
import Tile from "../../../common/components/Tile";
import { GlobalState } from "../../../common/types/GlobalState";
import { selectHeaderHeight } from "../../../common/slices/headerSlice";
import { MAIN_PADDING } from "../../../common/constants/numeric";
import styled from "styled-components";
import { routes } from "../../../common/constants/appRoutes";
import { selectBreadcrumbHeight } from "../../../common/slices/breadcrumbsSlice";

type Props = {
  headerHeight: number;
  breadcrumbsHeight: number;
};

const Container = styled.div<{
  $headerHeight: number;
  $breadcrumbsHeight: number;
}>`
  height: calc(
    100vh -
      ${({ $headerHeight, $breadcrumbsHeight }) =>
        $headerHeight + 2 * MAIN_PADDING + $breadcrumbsHeight}px
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const StyledTile = styled(Tile)`
  flex: 0 1 auto;
  width: 60vw;
`;

const EditorHomePage = ({ headerHeight, breadcrumbsHeight }: Props) => {
  return (
    <Container
      $headerHeight={headerHeight}
      $breadcrumbsHeight={breadcrumbsHeight}
    >
      <StyledTile href={routes.HOME.EDIT.DYNAMIC_PAGE_LIST.path}>
        <p>Pagine Dinamiche</p>
      </StyledTile>
      <StyledTile href={routes.HOME.EDIT.CARD_LIST.path}>
        <p>Card Homepage</p>
      </StyledTile>
    </Container>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    headerHeight: selectHeaderHeight(state),
    breadcrumbsHeight: selectBreadcrumbHeight(state),
  };
};

export default connect(mapStateToProps)(EditorHomePage);
