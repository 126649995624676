import { faPencil } from "@fortawesome/free-solid-svg-icons";
import Markdown from "../../../common/components/Markdown";
import { DynamicPageType } from "../../../common/types/DynamicPageType";
import BounceIconButton from "./BounceIconButton";

type Props = {
  page?: DynamicPageType;
  canEdit?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
};

const DynamicPageDisplay = ({ page, canEdit = false, setEditMode }: Props) => (
  <>
    <Markdown customStyle={page?.customStyle}>{page?.markdown}</Markdown>
    {canEdit && setEditMode && (
      <BounceIconButton icon={faPencil} onClick={() => setEditMode(true)} />
    )}
  </>
);
export default DynamicPageDisplay;
