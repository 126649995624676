import styled from "styled-components";
import { COLORS } from "../../common/styles/colors";
import { Checkbox, Radio } from "pretty-checkbox-react";
import Button from "../../common/components/buttons/Button";
import { minWidthMedia } from "../../common/styles/mediaQueries";

export const JustifyOrCenter = styled.p`
  text-align: justify;

  ${minWidthMedia.sm} {
    text-align: center;
  }
`;

export const InterestFormImage = styled.img`
  margin: 2.5vh auto;
`;

export const OptionsContainerRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
`;

export const OptionsContainerColumn = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  flex-basis: ${(1 / 5) * 100}%;
  gap: 25px;
  border: solid 1px ${COLORS.DARK_ORANGE};
  padding: 20px 0;
  margin: 2px;
`;

export const InterestFormTableTitle = styled.div`
  width: calc(5em + 2px);
  min-height: 30px;
  font-size: 18px;
  text-align: center;
  flex-basis: ${(1 / 5) * 100}%;
  color: ${COLORS.MID_ORANGE};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InterestFormCheckbox = styled(Checkbox)`
  margin-left: 13px;
`;

export const InterestFormRadio = styled(Radio)`
  margin-left: 13px;
`;

export const CheckboxInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  gap: 5px;
  justify-content: space-around;
`;

export const BigButton = styled(Button)`
  font-size: 1em;
`;
