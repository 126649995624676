import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import { CSSProperties, useRef } from "react";

type Props = {
  icon: IconProp;
  onAnimationEnd?: () => void;
  restartDelay?: number;
  playOnHover?: boolean;
  style?: CSSProperties;
  [x: string]: any;
};

export const restartAnimation = (
  element: HTMLElement | null,
  msDelay?: number
) => {
  if (!element) return;
  if (element) {
    const tempAnimation = element?.style.animation;
    element.style.animation = "none";
    setTimeout(() => {
      element.style.animation = tempAnimation;
    }, msDelay);
  }
};

const FAIcon = ({
  icon,
  onAnimationEnd,
  restartDelay,
  playOnHover,
  style,
  ...rest
}: Props) => {
  const ref = useRef(null);

  return (
    <FontAwesomeIcon
      ref={ref}
      icon={icon}
      style={style}
      {...rest}
      onAnimationEnd={() => {
        onAnimationEnd && onAnimationEnd();
        style?.animation &&
          restartDelay &&
          restartAnimation(ref.current, restartDelay);
      }}
      onMouseEnter={
        style?.animation && playOnHover
          ? debounce(() => restartAnimation(ref.current, 100), 2000, {
              leading: true,
            })
          : undefined
      }
    />
  );
};

export default FAIcon;
