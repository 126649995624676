import styled from "styled-components";
import { COLORS } from "../../common/styles/colors";

const StyledLink = styled.a`
  color: ${COLORS.MID_ORANGE};
  font-weight: bold;

  &:hover {
    color: ${COLORS.DARK_ORANGE};
  }

  &:active {
    color: ${COLORS.WHITE};
  }
`;

const InterestFormPrivacyPage = () => (
  <div style={{ textAlign: "justify" }}>
    <h1 id="informativa-sulla-privacy">Informativa sulla privacy</h1>
    <h2 id="modulo-manifestazione-di-interesse">
      Modulo manifestazione di interesse
    </h2>
    <small style={{ display: "block" }}>
      Data di entrata in vigore: 15/06/2024
    </small>
    <small style={{ display: "block" }}>Ultimo aggiornamento: 15/06/2024</small>
    <p>
      Questa Informativa sulla privacy descrive le politiche del gruppo sportivo
      informale identificato come &quot;Quadball Friuli-Venezia Giulia&quot;,
      nella persona di Michele Clabassi, sulla raccolta, l&#39;uso e la
      divulgazione delle informazioni che raccogliamo tramite il Modulo di
      manifestazione di interesse (
      <StyledLink href="https://quadball.fvg.it/interest">
        https://quadball.fvg.it/interest
      </StyledLink>
      ). (da qui noto come &quot;Interest Form&quot;). Compilando l&#39;Interest
      Form, acconsenti alla raccolta, all&#39;uso e alla divulgazione delle tue
      informazioni in conformità con questa Informativa sulla privacy. Se non
      acconsenti a quanto sopra, ti preghiamo di non inserire dati
      nell&#39;Interest Form.
    </p>
    <p>
      Possiamo modificare questa Informativa sulla privacy in qualsiasi momento.
      l&#39;Informativa aggiornata sarà pubblicata su questa pagina. La nuova
      Informativa sarà effettiva dal momento della pubblicazione, e si
      applicherà ad ogni dato inserito nell&#39;Interest Form a partire dal
      momento della pubblicazione. Per i dati raccolti in data precedente, vale
      quanto definito nell&#39;Informativa sulla privacy vigente al momento
      della raccolta degli stessi.
    </p>
    <h3 id="informazioni-che-raccogliamo">Informazioni che raccogliamo</h3>
    <p>
      Raccoglieremo e tratteremo le seguenti informazioni personali su di te,
      alcune delle quali in maniera facoltativa:
    </p>
    <ul>
      <li>Nome (ufficiale o elettivo)</li>
      <li>Email</li>
      <li>Cellulare</li>
      <li>Profilo Social Media</li>
      <li>Anno di nascita</li>
      <li>Località di domicilio</li>
      <li>Qualsiasi altra informazione tu voglia fornirci in modo autonomo</li>
    </ul>
    <h3 id="come-utilizziamo-le-tue-informazioni">
      Come utilizziamo le tue informazioni
    </h3>
    <p>
      Utilizzeremo le informazioni che raccogliamo su di te per i seguenti
      scopi:
    </p>
    <ul>
      <li>Comunicazioni relative alle attività della squadra</li>
      <li>Comunicazioni per fornirti informazioni di tuo interesse</li>
      <li>Raccolta di feedback</li>
    </ul>
    <p>
      Se vogliamo utilizzare le tue informazioni per qualsiasi altro scopo, ti
      chiederemo il consenso e utilizzeremo le tue informazioni solo dopo aver
      ricevuto il tuo consenso e solo per lo scopo(i) per cui hai concesso il
      consenso a meno che non siamo obbligati a fare diversamente per legge.
    </p>
    <h3 id="conservazione-delle-tue-informazioni">
      Conservazione delle tue informazioni
    </h3>
    <p>
      Conserveremo le tue informazioni personali con noi fino a 2 anni dalla
      data d&#39;invio, o per tutto il tempo necessario a soddisfare gli scopi
      per cui sono state raccolte come dettagliato in questa Informativa sulla
      privacy. Potremmo aver bisogno di conservare determinate informazioni per
      periodi più lunghi, come la conservazione / rendicontazione in conformità
      con la legge applicabile o per altri motivi legittimi come
      l&#39;applicazione dei diritti legali, la prevenzione delle frodi, ecc. Le
      informazioni anonime residue e le informazioni aggregate, che non ti
      identificano (direttamente o indirettamente), possono essere conservate
      indefinitamente.
    </p>
    <h3 id="i-tuoi-diritti">I tuoi diritti</h3>
    <p>
      Hai il diritto di accedere e rettificare o cancellare i tuoi dati
      personali o riceverne una copia, limitare o opporti al trattamento attivo
      dei tuoi dati, revocare qualsiasi consenso fornito per il loro
      trattamento, il diritto di presentare un reclamo a un&#39;autorità
      competente e altri diritti rilevanti ai sensi delle leggi applicabili. Per
      esercitare questi diritti, puoi scriverci a{" "}
      <StyledLink href="mailto:play@quadball.fvg.it">
        play@quadball.fvg.it
      </StyledLink>
      . Risponderemo alla tua richiesta in conformità con la legge applicabile.
    </p>
    <p>
      Nota che se non ci permetti di raccogliere o trattare le informazioni
      personali richieste o ritiri il consenso a trattare le stesse per gli
      scopi richiesti, non saremo in grado di contattarti per la tua
      partecipazione alle attività della squadra o per fornirti le informazioni
      che stai cercando.
    </p>
    <h3 id="cookie">Cookie</h3>
    <p>L&#39;Interest Form non utilizza cookie di alcun tipo.</p>
    <h3 id="sicurezza">Sicurezza</h3>
    <p>
      La sicurezza delle tue informazioni è importante per noi e adotteremo
      misure di sicurezza ragionevoli per prevenire la perdita, l&#39;uso
      improprio o l&#39;alterazione non autorizzata delle tue informazioni sotto
      il nostro controllo. Tuttavia, dati i rischi inerenti, non possiamo
      garantire la sicurezza assoluta e, di conseguenza, non possiamo assicurare
      o garantire la sicurezza di qualsiasi informazione che ci trasmetti e lo
      fai a tuo rischio. In particolare qualsiasi dato inviato tramite
      connessioni a reti o da dispositivi pubblici o condivisi è da considerarsi
      particolarmente a rischio.
    </p>
    <h3 id="link-di-terze-parti-e-utilizzo-delle-tue-informazioni">
      Link di terze parti e utilizzo delle tue informazioni
    </h3>
    <p>
      Il nostro Interest Form può contenere link ad altri siti web che non sono
      gestiti da noi. Questa Informativa sulla privacy non si applica alle
      politiche sulla privacy e ad altre pratiche di terze parti, inclusa
      qualsiasi terza parte che gestisca un sito web o servizio che potrebbe
      essere accessibile tramite un link presente sull&#39;Interest Form. Ti
      consigliamo vivamente di rivedere l&#39;informativa sulla privacy di ogni
      sito che visiti. Non abbiamo alcun controllo e non ci assumiamo alcuna
      responsabilità per i contenuti, le politiche sulla privacy o le pratiche
      di siti o servizi di terze parti.
    </p>
    <h3 id="responsabile-del-trattamento-dei-dati">
      Responsabile del trattamento dei dati
    </h3>
    <p>
      Responsabile del trattamento dei dati è Michele Clabassi. Se hai domande o
      preoccupazioni riguardo al trattamento delle tue informazioni in nostro
      possesso, oltre a qualsiasi richiesta di modifica o rimozione dei tuoi
      dati, puoi inviare un&#39;email a{" "}
      <StyledLink href="mailto:play@quadball.fvg.it">
        play@quadball.fvg.it
      </StyledLink>
      . Affronteremo le tue richieste in conformità con la legge applicabile.
    </p>
  </div>
);

export default InterestFormPrivacyPage;
