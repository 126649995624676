import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { loginRequest, selectError } from "../loginSlice";
import styled from "styled-components";

const LoginButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 25px;
  border-radius: 20px;
`;

const LoginForm = ({ loginRequest }: any) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const error = useSelector(selectError);

  const handleLogin = async (event: any) => {
    event.preventDefault();

    if (username && password) {
      await loginRequest({ username, password });
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };

  return (
    <form onKeyDown={handleKeyDown}>
      <div className="login-form">
        <label>Username:</label>
        <input
          type="text"
          value={username}
          autoComplete="username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <label>Password:</label>
        <input
          type="password"
          value={password}
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <LoginButton onClick={handleLogin}>Login</LoginButton>
        {error && <div className="error-message">{error}</div>}
      </div>
    </form>
  );
};

// Connect LoginForm to Redux store if needed
export default connect(null, { loginRequest })(LoginForm);
