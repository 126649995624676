import { createContext } from "react";

const MultiModalContext = createContext<{
  isModalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isModalOpen: false,
  setModalOpen: () => {},
});

export default MultiModalContext;
