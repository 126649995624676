import { LOADER_MARGIN } from "../constants/numeric";
import { COLORS } from "../styles/colors";
import Loader from "./Loader";

const ZINDEX = 8;

type Props = {
  fullscreen?: boolean;
};

const LoaderOverlay = ({ fullscreen = false }: Props) => {
  const position = fullscreen ? "fixed" : "absolute";

  return (
    <>
      <div
        style={{
          position,
          height: "calc(100% + 10px)",
          width: "calc(100% + 10px)",
          zIndex: ZINDEX,
          top: -5,
          left: -5,
          background: COLORS.BLACK,
          opacity: 0.75,
        }}
      />
      <Loader
        style={{
          position,
          top: 0,
          left: 0,
          height: `calc(100% - ${2 * LOADER_MARGIN}px`,
          width: `calc(100% - ${2 * LOADER_MARGIN}px`,
          zIndex: ZINDEX + 1,
        }}
      />
    </>
  );
};

export default LoaderOverlay;
