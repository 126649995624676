import { useContext } from "react";
import { InterestFormContext } from "../InterestForm";
import { InterestFormState } from "../../interestFormTypes";
import seekerImg from "../../../../common/images/photos/seeker.webp";
import {
  BigButton,
  InterestFormImage,
  JustifyOrCenter,
} from "../../interestFormComponents";
import CreditsLabel from "../../../../common/components/CreditsLabel";
import { css } from "styled-components";
import { AccentLink } from "../../../../common/components/links/styledLinks";
import { routes } from "../../../../common/constants/appRoutes";

const Step1 = () => {
  const { answerStep } = useContext(InterestFormContext);

  return (
    <>
      <JustifyOrCenter>
        Nato come quidditch, il quadball è iniziato da un'idea fantastica ma dal
        2005 è uno sport reale e coinvolgente. Scopri come saperne di più e come
        unirti a noi per (ri)portarlo in Friuli-Venezia Giulia!
      </JustifyOrCenter>
      <div style={{ position: "relative", display: "flex" }}>
        <InterestFormImage
          src={seekerImg}
          style={{ maxHeight: 400, height: "40vh" }}
        />
        <CreditsLabel
          media="photo"
          plural
          title="Van Klaveren Quidditch Photography"
          url="https://www.facebook.com/VanKlaverenQP"
          background={css`
            background: rgb(33, 33, 33, 0.5);
          `}
        />
      </div>
      <center style={{ fontWeight: "bold" }}>Vuoi giocare con noi?</center>
      <BigButton
        onClick={() =>
          answerStep({ needMoreInfo: false } as InterestFormState, 3)
        }
      >
        Sì
      </BigButton>
      <BigButton
        onClick={() =>
          answerStep({ needMoreInfo: true } as InterestFormState, 2)
        }
      >
        Vorrei saperne di più
      </BigButton>
      <center>
        <AccentLink href={routes.INTEREST_FORM.INTEREST_FORM_PRIVACY.path}>
          Informativa sulla privacy
        </AccentLink>
      </center>
    </>
  );
};

export default Step1;
