import { createSlice } from "@reduxjs/toolkit";
import { User } from "../types/User";
import { GlobalState } from "../types/GlobalState";
import { UserRole } from "../enums/UserRole";

type State = {
  user?: User;
};

const appSlice = createSlice({
  name: "app",
  initialState: {},
  reducers: {
    initializeApp: () => {},
    userContextSuccess: (state: State, { payload: { data } }) => {
      state.user = {
        ...data,
        role: UserRole[data.role as keyof typeof UserRole],
      };
    },
  },
});

export const { initializeApp, userContextSuccess } = appSlice.actions;

export const selectUser = (state: GlobalState) => state.app?.user;

export const selectIsUserInitialized = (state: GlobalState) =>
  state.app?.user &&
  Object.values(state.app?.user).some(
    (value) => value !== undefined && value !== null
  );

export const selectCanEdit = (state: GlobalState) => {
  const role = selectUser(state)?.role;
  return role === UserRole.EDITOR || role === UserRole.ADMIN;
};

export default appSlice.reducer;
