import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import FAIcon from "../../../common/components/FAIcon";
import { COLORS } from "../../../common/styles/colors";
import { CSSProperties } from "react";

type Props = {
  icon: IconDefinition;
  onClick: () => void;
  style?: CSSProperties;
};

const BounceIconButton = ({ icon, onClick, style }: Props) => (
  <div
    style={{
      ...style,
      paddingBottom: 0,
      display: "flex",
      justifyContent: "center",
    }}
  >
    <button
      onClick={onClick}
      style={{
        background: "none",
        padding: "10px",
        border: `solid ${COLORS.MID_ORANGE} 3px`,
        borderRadius: "50%",
      }}
    >
      <FAIcon
        style={{ color: `${COLORS.MID_ORANGE}`, height: "30px", width: "30px" }}
        icon={icon}
      />
    </button>
  </div>
);

export default BounceIconButton;
