import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import FAIcon from "../../../../common/components/FAIcon";
import { BigButton } from "../../interestFormComponents";

type Props = {
  handleBack: () => void;
};

const BackButton = ({ handleBack }: Props) => (
  <BigButton onClick={handleBack} style={{ flex: 1 }}>
    <FAIcon icon={faChevronLeft} />
  </BigButton>
);

export default BackButton;
