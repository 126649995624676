import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export const AccentLink = styled.a`
  color: ${COLORS.MID_ORANGE};
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`;
