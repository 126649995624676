import { DynamicPageType } from "../../../common/types/DynamicPageType";
import DynamicPageEditor from "./components/DynamicPageEditor";
import { routes } from "../../../common/constants/appRoutes";
import { GlobalState } from "../../../common/types/GlobalState";
import { selectPage } from "../../dynamicpage/slices/dynamicPageSlice";
import { connect } from "react-redux";

type Props = {
  page?: DynamicPageType;
};

const EditorNewDynamicPage = ({ page }: Props) => (
  <DynamicPageEditor
    page={
      page ??
      ({
        markdown: "",
      } as DynamicPageType)
    }
    closeEditMode={() => {
      window.location.href = routes.HOME.EDIT.DYNAMIC_PAGE_LIST.path;
    }}
  />
);

const mapStateToProps = (state: GlobalState) => {
  return {
    page: selectPage(state),
  };
};

export default connect(mapStateToProps)(EditorNewDynamicPage);
