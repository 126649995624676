import {
  CSSProperties,
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  useState,
} from "react";
import { COLORS } from "../../styles/colors";
import { createGlobalStyle } from "styled-components";
import Select from "react-select";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import {
  TextInput,
  OptionButtons,
  TextAreaInput,
  CssInput,
  InputFieldButton,
  Warning,
  CheckboxInput,
  InputGroup,
  Label,
} from "./inputStyledComponents";
import ImageInput from "./ImageInput";
import { VALIDATION_FAILED_CLASS } from "../../constants/strings";

type Props = {
  id: string;
  label?: string;
  onUpdate?: () => void;
  validationFailed?: boolean;
} & (TextProps | ImageProps | CheckboxProps | SelectProps);

type TextProps = {
  value?: string;
  type: "text" | "textarea" | "css" | "number";
  options?: never;
  customOptionStyle?: never;
  path?: never;
};

type ImageProps = {
  value?: string;
  type: "image";
  options?: never;
  customOptionStyle?: never;
  path?: string;
};

type CheckboxProps = {
  value?: boolean;
  type: "checkbox";
  options?: never;
  customOptionStyle?: never;
  path?: never;
};

type SelectProps = {
  value?: string;
  type: "dropdown";
  options: any;
  customOptionStyle?: (data: any) => {};
  path?: never;
};

const Input = forwardRef(
  (
    {
      id,
      value,
      label,
      type,
      options,
      customOptionStyle,
      onUpdate,
      path,
      validationFailed = false,
    }: Props,
    ref: ForwardedRef<any>
  ) => {
    const [useCss, setUseCss] = useState(false);
    const initialValue = !value && type === "dropdown" ? "" : value;
    const [currentStringValue, setCurrentStringValue] = useState(
      initialValue as string
    );

    const handleCssChange = (v: string) => {
      setCurrentStringValue(v);
      onUpdate && onUpdate();
    };

    const handleTextareaChange: ChangeEventHandler<HTMLTextAreaElement> = (
      event
    ) => {
      setCurrentStringValue(event.target.value);
      onUpdate && onUpdate();
    };

    const handleSelectChange = (selected: any) => {
      setCurrentStringValue(selected ? selected.value : "");
      onUpdate && setTimeout(onUpdate, 50);
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> &
      FocusEventHandler<HTMLTextAreaElement> = (event: any) => {
      event.target.value = event.target.value.trim();
      onUpdate && onUpdate();
    };

    let inputField;
    switch (type) {
      case "text":
        inputField = (
          <TextInput
            ref={ref}
            id={id}
            type="text"
            defaultValue={currentStringValue}
            onInput={onUpdate}
            onBlur={handleBlur}
            className={validationFailed ? VALIDATION_FAILED_CLASS : ""}
          />
        );
        break;
      case "image":
        inputField = (
          <ImageInput
            id={id}
            ref={ref}
            onUpdate={onUpdate}
            handleBlur={handleBlur}
            currentStringValue={currentStringValue}
            setCurrentStringValue={setCurrentStringValue}
            path={path}
            validationFailed={validationFailed}
          />
        );
        break;
      // TODO: Fix number input to only accept positive integers
      case "number":
        inputField = (
          <TextInput
            ref={ref}
            id={id}
            type="number"
            defaultValue={currentStringValue}
            onInput={onUpdate}
            onBlur={handleBlur}
            min={1}
            step={1}
            className={validationFailed ? VALIDATION_FAILED_CLASS : ""}
          />
        );
        break;
      case "textarea":
        inputField = (
          <TextAreaInput
            ref={ref}
            id={id}
            defaultValue={currentStringValue}
            onChange={handleTextareaChange}
            onBlur={handleBlur}
            className={validationFailed ? VALIDATION_FAILED_CLASS : ""}
          />
        );
        break;
      case "css":
        const GlobalStyles = createGlobalStyle`
        .rse-StyleEditor-isEmpty {
          background: initial !important;
        }
        .rse-Rule-selector {
          color: ${COLORS.WHITE} !important;
        }
        .rse-Checkbox-root {
          background-color: ${COLORS.WHITE};
        }
      `;
        inputField = (
          <>
            <GlobalStyles />
            {useCss ? (
              <>
                <CssInput
                  id={id}
                  defaultValue={currentStringValue ?? ""}
                  onChange={handleCssChange}
                  className={validationFailed ? VALIDATION_FAILED_CLASS : ""}
                />
                <textarea
                  ref={ref}
                  style={{ display: "none" }}
                  value={currentStringValue}
                  readOnly
                />
              </>
            ) : (
              <TextAreaInput
                id={id}
                ref={ref}
                defaultValue={currentStringValue}
                onChange={handleTextareaChange}
                onBlur={handleBlur}
                className={validationFailed ? VALIDATION_FAILED_CLASS : ""}
              />
            )}
            <OptionButtons>
              <InputFieldButton onClick={() => setUseCss(!useCss)}>
                {useCss ? "Disattiva" : "Attiva"} modalità CSS
              </InputFieldButton>
              {type === "css" && useCss && (
                <Warning>
                  Solo CSS base, non riconosce sintassi avanzata
                </Warning>
              )}
            </OptionButtons>
          </>
        );
        break;
      case "checkbox":
        inputField = (
          <CheckboxInput
            ref={ref}
            id={id}
            defaultChecked={value}
            shape="curve"
            bigger
            onInput={onUpdate}
            className={validationFailed ? VALIDATION_FAILED_CLASS : ""}
          />
        );
        break;
      case "dropdown":
        const controlStyle = {
          background: "unset",
          border: `solid 1px ${COLORS.MID_ORANGE}`,
          borderRadius: "7px 7px 0 0",
          ":hover": { borderColor: COLORS.MID_ORANGE },
        };
        const menuStyle = {
          background: COLORS.DARK_GRAY,
          border: `solid 1px ${COLORS.MID_ORANGE}`,
          borderRadius: "0 0 7px 7px",
          marginTop: 0,
          zIndex: 2,
        };
        const optionStyle = {
          background: "unset",
          ":hover": { background: COLORS.MID_GRAY },
        };
        inputField = (
          <>
            <Select
              id={id}
              defaultValue={value ? { value: value, label: value } : undefined}
              onChange={handleSelectChange}
              placeholder={null}
              isClearable
              isSearchable
              options={options}
              styles={{
                input: (styles) => ({ ...styles, color: "unset" }),
                control: (styles) => ({ ...styles, ...controlStyle }),
                singleValue: (styles, { data }) => ({
                  ...styles,
                  color: "unset",
                  ...(customOptionStyle ? customOptionStyle(data) : {}),
                }),
                menu: (styles) => ({ ...styles, ...menuStyle }),
                option: (styles, { data }) => ({
                  ...styles,
                  ...optionStyle,
                  ...(customOptionStyle ? customOptionStyle(data) : {}),
                }),
                container: (styles) => ({ ...styles, width: "100%" }),
              }}
              className={validationFailed ? VALIDATION_FAILED_CLASS : ""}
            />
            <input
              type="text"
              ref={ref}
              style={{ display: "none" }}
              value={currentStringValue}
              readOnly
            />
          </>
        );
    }

    let style: CSSProperties = { marginBottom: 16 };
    if (type === "checkbox")
      style = {
        ...style,
        minWidth: "calc(5em + 2px)",
      };

    const inputElement = (
      <>
        {label && <Label htmlFor={id}>{label}</Label>}
        {inputField}
      </>
    );

    return (
      <InputGroup style={style}>
        {type === "checkbox" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {inputElement}
          </div>
        ) : (
          inputElement
        )}
      </InputGroup>
    );
  }
);

export default Input;
