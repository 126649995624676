import React, { useEffect } from "react";
import HomePage from "../pages/home/HomePage";
import LoginPage from "../pages/login/LoginPage";
import {
  Outlet,
  RouterProviderProps,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectHeaderHeight } from "../common/slices/headerSlice";
import styled from "styled-components";
import { MAIN_PADDING } from "../common/constants/numeric";
import DynamicPage from "../pages/dynamicpage/DynamicPage";
import { routes } from "../common/constants/appRoutes";
import { updateLocation } from "./routerSlice";
import EditorHomePage from "../pages/edit/home/EditorHomePage";
import EditorDynamicPageList from "../pages/edit/dynamicpage/EditorDynamicPageList";
import EditorDynamicPageEdit from "../pages/edit/dynamicpage/EditorDynamicPageEdit";
import EditorNewDynamicPage from "../pages/edit/dynamicpage/EditorNewDynamicPage";
import EditorDynamicPagePreview from "../pages/edit/dynamicpage/EditorDynamicPagePreview";
import EditorCardList from "../pages/edit/card/EditorCardList";
import EditorNewCard from "../pages/edit/card/EditorNewCard";
import EditorCardEdit from "../pages/edit/card/EditorCardEdit";
import Breadcrumbs from "../common/components/Breadcrumbs";
import InterestForm from "../pages/interestform/components/InterestForm";
import InterestFormPrivacyPage from "../pages/privacy/InterestFormPrivacyPage";
import WorkInProgressPage from "../pages/wip/WorkInProgressPage";

const StyledMain = styled.main`
  & > * {
    padding: ${MAIN_PADDING}px;
  }
`;

const RouteLayout = () => {
  const headerHeight = useSelector(selectHeaderHeight);

  const dispatch = useDispatch();
  const { pathname: currentPath } = useLocation();

  useEffect(() => {
    dispatch(updateLocation(currentPath));
  }, [currentPath, dispatch]);

  return (
    <StyledMain style={{ marginTop: `${headerHeight}px` }}>
      <Breadcrumbs />
      <Outlet />
    </StyledMain>
  );
};

export const generateRouter = (): RouterProviderProps["router"] => {
  return createBrowserRouter([
    {
      path: "/",
      element: <RouteLayout />,
      children: [
        {
          path: routes.LOGIN.path,
          element: <LoginPage />,
        },
        {
          path: routes.HOME.path,
          element: <HomePage />,
        },
        {
          path: routes.HOME.DYNAMIC_PAGE.path,
          element: <DynamicPage />,
        },
        {
          path: routes.INTEREST_FORM.path,
          element: <InterestForm />,
        },
        {
          path: routes.INTEREST_FORM.INTEREST_FORM_PRIVACY.path,
          element: <InterestFormPrivacyPage />,
        },
        {
          path: routes.WORK_IN_PROGRESS.path,
          element: <WorkInProgressPage />,
        },
        {
          path: "/edit",
          children: [
            {
              path: routes.HOME.EDIT.path,
              element: <EditorHomePage />,
            },
            {
              path: routes.HOME.EDIT.DYNAMIC_PAGE_LIST.path,
              element: <EditorDynamicPageList />,
            },
            {
              path: routes.HOME.EDIT.DYNAMIC_PAGE_LIST.DYNAMIC_PAGE_NEW.path,
              element: <EditorNewDynamicPage />,
            },
            {
              path: routes.HOME.EDIT.DYNAMIC_PAGE_LIST.DYNAMIC_PAGE_EDIT.path,
              element: <EditorDynamicPageEdit />,
            },
            {
              path: routes.DYNAMIC_PAGE_PREVIEW.path,
              element: <EditorDynamicPagePreview />,
            },
            {
              path: routes.HOME.EDIT.CARD_LIST.path,
              element: <EditorCardList />,
            },
            {
              path: routes.HOME.EDIT.CARD_LIST.CARD_NEW.path,
              element: <EditorNewCard />,
            },
            {
              path: routes.HOME.EDIT.CARD_LIST.CARD_EDIT.path,
              element: <EditorCardEdit />,
            },
          ],
        },
      ],
    },
  ]);
};
