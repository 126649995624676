import { useContext, useEffect, useState } from "react";
import Dialog from "../modals/Dialog";
import LoaderOverlay from "../LoaderOverlay";
import Button from "./Button";
import { Variant } from "../../enums/Variant";
import ButtonWithNotification from "./ButtonWithSuccessNotification";
import MultiModalContext from "../../contexts/MultiModalContext";

type EditorButtonsProps = {
  saving: boolean;
  saveError: boolean;
  save: () => void;
  areThereChanges: () => boolean;
  closeEditMode: () => void;
};

type DialogAndLoaderProps = {
  closeEditMode: () => void;
  saving: boolean;
};

type Props = EditorButtonsProps & DialogAndLoaderProps;

const editorButtonStyle = { width: 200, flex: "1 0 auto" };

let count = 0;

const EditorButtons = ({
  saving,
  saveError,
  save,
  areThereChanges,
  closeEditMode,
}: EditorButtonsProps) => {
  const { setModalOpen } = useContext(MultiModalContext);
  const handleExit = () =>
    areThereChanges() ? setModalOpen(true) : closeEditMode();

  return (
    <div
      style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
    >
      <Button
        variant={Variant.Secondary}
        onClick={handleExit}
        style={editorButtonStyle}
      >
        Esci
      </Button>
      <ButtonWithNotification
        state={saving}
        error={saveError}
        initialState={false}
        preStates={[true]}
        postStates={[false]}
        style={editorButtonStyle}
        delay={500}
      >
        <Button onClick={save} disabled={!areThereChanges()}>
          Salva
        </Button>
      </ButtonWithNotification>
    </div>
  );
};

const DialogAndLoader = ({ closeEditMode, saving }: DialogAndLoaderProps) => {
  const { isModalOpen, setModalOpen } = useContext(MultiModalContext);

  return (
    <>
      <Dialog
        isOpen={isModalOpen}
        closeModal={() => setModalOpen(false)}
        closeEdit={closeEditMode}
      >
        Sono presenti modifiche, chiudendo l'editor senza salvare verranno
        scartate. Uscire?
      </Dialog>
      {saving && <LoaderOverlay fullscreen />}
    </>
  );
};

const EditorButtonsMultiModal = ({
  saving,
  saveError,
  save,
  areThereChanges,
  closeEditMode,
}: Props) => {
  const [first, setFirst] = useState(false);
  useEffect(() => {
    setFirst(count++ === 0);

    return () => {
      count--;
    };
  }, []);

  return (
    <>
      {first && (
        <DialogAndLoader closeEditMode={closeEditMode} saving={saving} />
      )}
      <EditorButtons
        saving={saving}
        saveError={saveError}
        save={save}
        areThereChanges={areThereChanges}
        closeEditMode={closeEditMode}
      />
    </>
  );
};

export default EditorButtonsMultiModal;
