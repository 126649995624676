import React, { ReactNode, createContext, useState } from "react";
import usePromptOnNavigation from "../../../common/hooks/usePromptOnNavigation";
import Step from "./steps/Step";
import { InterestFormState } from "../interestFormTypes";
import BackButton from "./buttons/BackButton";
import styled from "styled-components";
import { minWidthMedia } from "../../../common/styles/mediaQueries";
import { MAIN_PADDING } from "../../../common/constants/numeric";
import { connect, useDispatch } from "react-redux";
import {
  saveInterestFormRequest,
  selectInterestFormAnswerId,
} from "../slice/interestFormSlice";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "../styles/interestFormStyles.css";
import axios from "axios";
import { replace } from "lodash";
import { apiRoutes } from "../../../common/constants/appRoutes";
import { GlobalState } from "../../../common/types/GlobalState";

export type Context = {
  answers: InterestFormState;
  answerStep: (partialState: InterestFormState, nextStep?: number) => void;
  handleNo: () => void;
  step: number;
  backButton: ReactNode;
};
export const InterestFormContext = createContext(
  undefined as unknown as Context
);

type Props = {
  answerId?: boolean;
};

const WizardContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 90vw;

  & p:first-child {
    margin-top: 0;
  }

  ${minWidthMedia.md} {
    width: 70vw;
  }

  ${minWidthMedia.lg} {
    width: 50vw;
  }
`;

const InterestForm = ({ answerId }: Props) => {
  usePromptOnNavigation();
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [stepsHistory, setStepsHistory] = useState([] as number[]);
  const [answers, setAnswers] = useState({} as InterestFormState);
  const [directionForward, setDirectionForward] = useState(true);

  const updateStep = (newStep: number) => {
    setStepsHistory(stepsHistory.concat(step));
    setStep(newStep);
  };

  const handleBack = () => {
    const updatedStepsHistory = [...stepsHistory];
    if (stepsHistory?.length === 0) return;
    const backStep: number = updatedStepsHistory.pop() ?? 1;
    setDirectionForward(false);
    setTimeout(() => {
      setStep(backStep);
      setStepsHistory(updatedStepsHistory);
    }, 100);
  };

  const saveSurvey = async (answers: InterestFormState) => {
    try {
      dispatch(saveInterestFormRequest(answers));
    } catch (error) {
      console.debug("progressive save error");
    }
  };

  const deleteSurvey = async () => {
    try {
      answerId &&
        (await axios.delete(
          replace(
            apiRoutes.INTEREST_FORM,
            ":leadId",
            answerId?.toString() ?? ""
          )
        ));
    } catch (e: any) {
      console.error(e);
    }
  };

  const answerStep = (partialState: InterestFormState, nextStep?: number) => {
    const newState = {
      ...answers,
      ...partialState,
    };
    setAnswers(newState);
    saveSurvey(newState);
    if (nextStep) {
      setDirectionForward(true);
      setTimeout(() => updateStep(nextStep), 100);
    }
  };

  const handleNo = () => {
    deleteSurvey();
    setDirectionForward(true);
    setTimeout(() => updateStep(-1), 100);
  };

  const backButton = <BackButton handleBack={handleBack} />;

  return (
    <TransitionGroup>
      <CSSTransition
        key={step}
        timeout={1000}
        classNames={directionForward ? "slide" : "slide-back"}
      >
        <div
          style={{
            display: "flex",
            width: `calc(100vw - ${MAIN_PADDING * 2}px`,
          }}
        >
          <WizardContainer>
            <InterestFormContext.Provider
              value={{ answers, answerStep, handleNo, step, backButton }}
            >
              <Step />
            </InterestFormContext.Provider>
          </WizardContainer>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    answerId: selectInterestFormAnswerId(state),
  };
};

export default connect(mapStateToProps)(InterestForm);
