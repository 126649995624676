import { ReactNode } from "react";
import LoaderOverlay from "./LoaderOverlay";

type Props = {
  showLoader?: boolean;
  children: ReactNode;
};

const LoaderOverlayWrapper = ({ showLoader = false, children }: Props) => (
  <div style={{ position: "relative" }}>
    {children}
    {showLoader && <LoaderOverlay />}
  </div>
);
export default LoaderOverlayWrapper;
