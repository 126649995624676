import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../common/slices/appSlice";
import loginReducer from "../pages/login/loginSlice";
import headerReducer from "../common/slices/headerSlice";
import breadcrumbsReducer from "../common/slices/breadcrumbsSlice";
import homePageReducer from "../pages/home/slices/homePageSlice";
import dynamicPageReducer from "../pages/dynamicpage/slices/dynamicPageSlice";
import imgReducer from "../common/slices/imgSlice";
import routerReducer from "../router/routerSlice";
import interestFormReducer from "../pages/interestform/slice/interestFormSlice";
import appSaga from "../common/sagas/appSaga";
import loginSaga from "../pages/login/loginSaga";
import createSagaMiddleware from "redux-saga";
import homePageSaga from "../pages/home/sagas/homePageSaga";
import dynamicPageSaga from "../pages/dynamicpage/sagas/dynamicPageSaga";
import interestFormSaga from "../pages/interestform/sagas/interestFormSaga";

const sagaMiddleware: any = createSagaMiddleware();

const store = configureStore({
  reducer: {
    app: appReducer,
    login: loginReducer,
    header: headerReducer,
    breadcrumbs: breadcrumbsReducer,
    home: homePageReducer,
    dynamicPage: dynamicPageReducer,
    img: imgReducer,
    router: routerReducer,
    interestForm: interestFormReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          "payload.config",
          "payload.request",
          "payload.headers",
        ],
      },
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(appSaga);
sagaMiddleware.run(loginSaga);
sagaMiddleware.run(homePageSaga);
sagaMiddleware.run(dynamicPageSaga);
sagaMiddleware.run(interestFormSaga);

// Export the store
export default store;
