import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GlobalState } from "../types/GlobalState";

type State = {
  height: number;
  maxHeight: number;
};

const initialState = {
  height: 0,
  maxHeight: 0,
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    updateHeight: (state: State, { payload }: PayloadAction<number>) => {
      state.height = payload;
      state.maxHeight = Math.max(state.maxHeight, payload);
    },
  },
});

export const { updateHeight } = headerSlice.actions;

export const selectHeaderHeight = (state: GlobalState) => state.header?.height;

export const selectMaxHeaderHeight = (state: GlobalState) =>
  state.header?.maxHeight;

export default headerSlice.reducer;
