import useFinalSave from "../../hooks/useFinalSave";

const StepNoWithComments = () => {
  useFinalSave();

  return (
    <div>
      <center>
        Grazie di averci lasciato un messaggio! Se hai segnalato qualcosa che
        possiamo migliorare tieni d'occhio il sito e i social, consideriamo
        seriamente ogni commento!
      </center>
    </div>
  );
};

export default StepNoWithComments;
