import { routes } from "../../../common/constants/appRoutes";
import { GlobalState } from "../../../common/types/GlobalState";
import { connect } from "react-redux";
import { HomePageCardType } from "../../../common/types/HomePageCardType";
import { selectHomePageCard } from "../../home/slices/homePageSlice";
import CardEditor from "./components/CardEditor";

type Props = {
  page?: HomePageCardType;
};

const EditorNewCard = ({ page }: Props) => (
  <CardEditor
    card={
      page ??
      ({
        markdown: "",
      } as HomePageCardType)
    }
    closeEditMode={() => {
      window.location.href = routes.HOME.EDIT.CARD_LIST.path;
    }}
  />
);

const mapStateToProps = (state: GlobalState) => {
  return {
    page: selectHomePageCard(state),
  };
};

export default connect(mapStateToProps)(EditorNewCard);
