import { connect, useDispatch } from "react-redux";
import { GlobalState } from "../../../common/types/GlobalState";
import { HomePageCardType } from "../../../common/types/HomePageCardType";
import {
  homePageECardsRequest,
  selectHomePageCards,
} from "../../home/slices/homePageSlice";
import { useEffect, useState } from "react";
import { EditorFooterButtons, EditorLink } from "../editorComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faFileCirclePlus,
  faFilePen,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../common/styles/colors";
import { routes } from "../../../common/constants/appRoutes";
import { editorTableStyle } from "../editorStyle";
import DataTable from "react-data-table-component";
import CardSorter from "./components/CardSorter";

type Props = {
  cards: HomePageCardType[];
};

const EditorCardList = ({ cards }: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(homePageECardsRequest());
  }, [dispatch]);

  const [sortMode, setSortMode] = useState(false);

  const columns = [
    {
      name: "Titolo",
      cell: (row: HomePageCardType) => (
        <EditorLink
          href={`${routes.HOME.EDIT.CARD_LIST.CARD_EDIT.path.replace(
            ":cardId",
            row.internalId
          )}`}
        >
          <FontAwesomeIcon icon={faFilePen} /> {row.internalId}
        </EditorLink>
      ),
      sortable: true,
    },
    {
      name: "Attiva",
      cell: (row: HomePageCardType) =>
        row.active ? (
          <FontAwesomeIcon icon={faEye} color={COLORS.MID_GREEN} size="2x" />
        ) : (
          <FontAwesomeIcon icon={faEyeSlash} color={COLORS.MID_RED} size="2x" />
        ),
      sortable: true,
      grow: 0,
    },
  ];

  return (
    <>
      {cards ? (
        sortMode ? (
          <CardSorter closeSortMode={() => setSortMode(false)} />
        ) : (
          <>
            <DataTable
              keyField={"internalId"}
              columns={columns}
              data={cards}
              customStyles={{
                headRow: editorTableStyle,
                rows: editorTableStyle,
                responsiveWrapper: { style: { boxSizing: "border-box" } },
              }}
              pagination
            />
            <EditorFooterButtons>
              <EditorLink href={routes.HOME.EDIT.CARD_LIST.CARD_NEW.path}>
                <FontAwesomeIcon icon={faFileCirclePlus} /> Nuova card
              </EditorLink>
              <EditorLink
                onClick={() => setSortMode(true)}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faSort} /> Riordina
              </EditorLink>
            </EditorFooterButtons>
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    cards: selectHomePageCards(state),
  };
};

export default connect(mapStateToProps)(EditorCardList);
