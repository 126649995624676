import { useContext } from "react";
import { InterestFormContext } from "../InterestForm";
import { InterestFormState } from "../../interestFormTypes";
import { BigButton } from "../../interestFormComponents";
import Accordion, {
  ResponsiveImg,
  Section,
} from "../../../../common/components/Accordion";
import startingProcedure from "../../../../common/images/photos/starting_procedure.webp";
import bludgerChaos from "../../../../common/images/photos/bludger_chaos.webp";
import quafflers from "../../../../common/images/photos/quafflers.webp";
import seekerSnitch from "../../../../common/images/photos/seeker_snitch.webp";
import tournamentVibes from "../../../../common/images/photos/tournament_vibes.webp";
import beaterTackle from "../../../../common/images/photos/beater_tackle.webp";
import hinkyCelebration from "../../../../common/images/photos/hinky_celebration.webp";
import cormor from "../../../../common/images/photos/cormor.webp";

const Step2 = () => {
  const { answerStep, handleNo } = useContext(InterestFormContext);

  return (
    <>
      <Accordion>
        <Section header="Il quadball">
          <img src={startingProcedure} alt="Quadball, inizio di una partita" />
          <p>
            Ispirato al Quidditch, lo sport di fantasia del mondo potteriano, il
            quadball ha fatto molta strada negli ultimi vent'anni. Da una
            competizione intramuraria con forte spirito goliardico, è diventato
            uno sport legittimo diffuso a livello internazionale.
          </p>
          <p>
            I quattro ruoli hanno funzioni uniche e si intrecciano tra sé per
            dare vita al quadball. Questo permette a ogni atleta di trovare la
            propria dimensione ideale, con dinamiche che non si trovano in
            nessuna altra disciplina.
          </p>
        </Section>
        <Section header="I ruoli">
          <p>I ruoli sono ripresi da quelli della versione originale.</p>
          <p>
            <b>Portiere</b> e <b>Cacciatori</b>: in tutto 4 per squadra, usano
            la pluffa per segnare gol e il loro gioco ha elementi in comune con
            pallamano, pallacanestro, rugby.
          </p>
          <ResponsiveImg
            src={quafflers}
            alt="Quadball, portiere e cacciatori impegnati in un'azione"
          />
          <p>
            <b>Battitori</b>: 2 per squadra. Con i 3 bolidi seguono una
            meccanica simile al dodgeball e supportano l'intera squadra,
            eliminando i giocatori avversari temporaneamente.
          </p>
          <ResponsiveImg
            src={bludgerChaos}
            alt="Quadball, 4 battitori impegnati in un'azione vicino agli anelli che coinvolge tutti 3 i bolidi"
          />
          <p>
            <b>Cercatore</b>: ha il compito di catturare il boccino. Questo
            entra in gioco solo al ventesimo minuto. Rispetto al Quidditch ha un
            valore minore, e non sempre termina la partita.
          </p>
          <ResponsiveImg
            src={seekerSnitch}
            alt="Quadball, cercatore in tuffo controllato dal boccino"
          />
          <p>
            <b>Boccino o boccinatore (Flag Runner)</b>: un ufficiale di gara che
            protegge in modo imparziale il boccino vero e proprio. Questo fa
            parte del suo equipaggiamento, come lo "scalpo" nell'omonimo gioco
            oppure le bandierine nel flag football. Il Flag Runner può ricorrere
            a vari mezzi, dalla semplice evasione a leve e proiezioni.
          </p>
        </Section>
        <Section header="La Maximum Gender Rule">
          <img
            src={beaterTackle}
            alt="Quadball, battitrice placca un battitore"
          />
          <p>
            Il quadball è misto per regolamento. La Maximum Gender Rule consente
            a una squadra di schierare al massimo 3 giocatori su 6 - o 4 su 7
            quando boccino e cercatori sono in campo - che si identifichino
            nello stesso genere.
          </p>
        </Section>
        <Section header="Le competizioni">
          <img
            src={tournamentVibes}
            alt="Quadball, tenda merchandising a un torneo, campo sullo sfondo"
          />
          <p>
            In Italia l'Associazione Italiana Quidditch (AIQ) coordina i club
            nazionali e organizza gli eventi ufficiali. I tornei, italiani e
            internazionali, si svolgono solitamente nel fine settimana. Sono
            occasione di ritrovo per la comunità oltre all'aspetto sportivo.
            <p></p>
            Si giocano anche frequenti tornei amichevoli, non solo con le
            normali squadre di appartenenza. Tra questi anche i tornei
            "fantasy", dove tutte le squadre sono formate ad hoc, spesso con un
            sistema di draft.
          </p>
        </Section>
        <Section header="Quadball in Friuli-Venezia Giulia">
          <p>
            Per qualche tempo a Udine è esistita la squadra delle Fenicis
            Furlanis, purtroppo sciolta già da qualche anno.
          </p>
          <p>
            Vogliamo formare un gruppo consistente su tutto il territorio
            regionale. Ci alleneremo e gareggeremo come squadra unica, fino ad
            avere numeri sufficienti per team cittadini o almeno provinciali.
            Nel lungo termine ci piacerebbe avere almeno tre squadre per una
            competizione regionale ricorrente.
          </p>
          <img
            src={cormor}
            alt="Quadball, allenamento aperto al Parco del Cormor, Udine, a fine 2023"
          />
          <p>
            Al momento ci dividiamo principalmente in due piccoli gruppi misti
            di veterani e nuovi interessati, nelle zone di Udine e Trieste.
          </p>
        </Section>
        <Section header="Obiettivi e impegno">
          <p>
            Le nostre mire iniziali sono semplici: allenarci e giocare almeno
            una volta al mese. Accogliere tutti i livelli di esperienza
            sportiva. Fornire ai più competitivi le basi per partecipare a
            tornei in prestito ad un'altra squadra. Partecipare come squadra ad
            almeno un evento entro la nostra prima stagione.
          </p>
          <img
            src={hinkyCelebration}
            alt="La squadra di quadball di Bologna in un momento di celebrazione durante un torneo"
          />
          <p>
            Entro la seconda stagione vogliamo avere una rosa competente, in
            grado di togliersi soddisfazioni in campo. Rimarremo aperti anche a
            chi vuole solo un passatempo, ma vorremmo che almeno parte del
            gruppo diventi più dedicata, allenandosi con più frequenza con
            l'obiettivo della top 4 italiana - risultato utile per qualificarsi
            alle competizioni europee.
          </p>
          <p>
            Abbiamo tra noi giocatori con anni di esperienza nel quadball e
            nella Nazionale Italiana, intendiamo sfruttarli perché il nostro
            angolino d'Italia si faccia valere.
          </p>
        </Section>
      </Accordion>
      <center>
        <h3>Ti abbiamo convinto!?</h3>
      </center>
      <BigButton
        onClick={() =>
          answerStep({ needMoreInfo: false } as InterestFormState, 3)
        }
      >
        Sì
      </BigButton>
      <BigButton
        onClick={() =>
          answerStep({ needMoreInfo: true } as InterestFormState, 3)
        }
      >
        Vorrei ancora qualche informazione
      </BigButton>
      <BigButton onClick={handleNo}>No</BigButton>
    </>
  );
};

export default Step2;
