import { DynamicPageType } from "../DynamicPageType";

export type DynamicPageDTO = {
  internal_id: string;
  title: string;
  markdown: string;
  custom_style: string;
  active: boolean;
};

export const convertDTO = (dto: DynamicPageDTO): DynamicPageType =>
  (({
    internal_id: internalId,
    title,
    markdown,
    custom_style: customStyle,
    active,
  }): DynamicPageType => ({
    internalId,
    title,
    markdown,
    customStyle,
    active,
  }))(dto);
