import { Variant } from "../../enums/Variant";
import { HomePageCardType } from "../HomePageCardType";

export type HomePageCardDTO = {
  internal_id: string;
  markdown: string;
  variant: keyof typeof Variant;
  bg_image_url: string;
  columns: number;
  custom_style: string;
  page_route: string;
  dynamic_page_id: string;
  external_link: string;
  order: number;
  active: boolean;
};

export const convertDTO = (item: HomePageCardDTO): HomePageCardType =>
  ({
    internalId: item.internal_id,
    markdown: item.markdown,
    variant: item.variant ? Variant[item.variant] : undefined,
    bgImageUrl: item.bg_image_url,
    columns: item.columns,
    customStyle: item.custom_style,
    pageRoute: item.page_route,
    dynamicPageId: item.dynamic_page_id,
    externalLink: item.external_link,
    order: item.order,
    active: item.active,
  } as unknown as HomePageCardType);
