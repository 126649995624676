import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  DiffSourceToggleWrapper,
  InsertImage,
  InsertTable,
  InsertThematicBreak,
  ListsToggle,
  MDXEditor,
  UndoRedo,
  diffSourcePlugin,
  headingsPlugin,
  imagePlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import { ForwardedRef, forwardRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { imageCdnUpload } from "../../utils/imgUtils";

type Props = {
  markdown: string;
  onUpdate: () => void;
  path?: string;
};

const StyledEditor = styled(MDXEditor)`
  border: solid 1px #dd8833;
  border-radius: 7px 7px 0 0;

  .mdxeditor-root-contenteditable > div {
    background-color: #161616 !important;
    color: #fff !important;
  }

  .mdxeditor-toolbar {
    box-sizing: border-box;
  }
`;

const GlobalStyles = createGlobalStyle`
  .mdxeditor-popup-container {
    border: 0;
    border-radius: 0;
    margin: 0 20px;
  }
`;

const MarkdownEditor = forwardRef(
  ({ markdown, onUpdate, path }: Props, ref: ForwardedRef<any>) => {
    const [currentValue, setCurrentValue] = useState(markdown);
    const handleChange = (v: string) => {
      setCurrentValue(v);
      setTimeout(onUpdate, 100);
    };

    const sanitizedMarkdown = markdown ?? "";

    return (
      <>
        <GlobalStyles />
        <StyledEditor
          onChange={handleChange}
          markdown={sanitizedMarkdown}
          plugins={[
            quotePlugin(),
            listsPlugin(),
            headingsPlugin(),
            tablePlugin(),
            linkPlugin(),
            linkDialogPlugin(),
            imagePlugin({
              imageUploadHandler: (f) => imageCdnUpload(f, path),
            }),
            thematicBreakPlugin(),
            markdownShortcutPlugin(),
            diffSourcePlugin({
              diffMarkdown: sanitizedMarkdown,
              viewMode: "rich-text",
            }),
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  <DiffSourceToggleWrapper>
                    <UndoRedo />
                    <BlockTypeSelect />
                    <BoldItalicUnderlineToggles />
                    <CreateLink />
                    <InsertImage />
                    <InsertThematicBreak />
                    <ListsToggle />
                    <InsertTable />
                  </DiffSourceToggleWrapper>
                </>
              ),
            }),
          ]}
        />
        <textarea
          ref={ref}
          style={{ display: "none" }}
          value={currentValue}
          readOnly
        />
      </>
    );
  }
);

export default MarkdownEditor;
