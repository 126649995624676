import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import styled from "styled-components";
import { Variant, variantToColors } from "../../enums/Variant";

type Props = {
  variant?: Variant;
  children?: ReactNode;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type StyleProps = {
  $variant?: Variant;
};

const StyledButton = styled.button<StyleProps>`
  border: 0;
  border-radius: 5px;
  padding: 20px;
  margin: 5px;
  background-color: ${({ $variant }) =>
    variantToColors[$variant || Variant.Primary]["main"]};
  color: ${({ $variant }) =>
    variantToColors[$variant || Variant.Primary]["text"]};

  font-size: 0.9em;
  font-weight: bold;

  &:hover {
    background-color: ${({ $variant }) =>
      variantToColors[$variant || Variant.Primary]["active"]};

    &:disabled {
      background-color: ${({ $variant }) =>
        variantToColors[$variant || Variant.Primary]["main"]};
    }
  }

  &:disabled {
    cursor: unset;
    opacity: 0.5;
  }

  &:active {
    transform: scale(0.95);

    &:disabled {
      transform: unset;
    }
  }
`;

const Button = ({ children, variant, ...rest }: Props) => (
  <StyledButton $variant={variant} {...rest}>
    {children}
  </StyledButton>
);
export default Button;
