import { useContext } from "react";
import { InterestFormContext } from "../InterestForm";
import WhatsappLink from "../WhatsappLink";
import { ReactComponent as WhatsappQR } from "../../../../common/images/svg/qfvg-qr-code.svg";
import useFinalSave from "../../hooks/useFinalSave";

const Step10 = () => {
  useFinalSave();
  const { answers } = useContext(InterestFormContext);

  return (
    <div>
      <center>
        Grazie,{" "}
        {answers.needMoreInfo
          ? "ti contatteremo per rispondere alle tue domande!"
          : "ti contatteremo per il prossimo allenamento o evento!"}
      </center>
      <div style={{ textAlign: "center", margin: "20px 0" }}>
        <WhatsappLink />
        <WhatsappQR style={{ width: "75%", height: "auto" }} />
      </div>
    </div>
  );
};

export default Step10;
